<template>
  <!-- Compute -->

  <svg
    id="fi_4144964"
    enable-background="new 0 0 512 512"
    :height="height"
    viewBox="0 0 512 512"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-2319.000000, -291.000000)"
        :fill="fill"
        fill-rule="nonzero"
      >
        <g transform="translate(1716.000000, 291.000000)">
          <g transform="translate(603.000000, 0.000000)">
            <path
              class="color-background"
              d="m467 0h-422c-24.814 0-45 20.186-45 45v60c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45v-60c0-24.814-20.186-45-45-45zm-392 90c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm242 0h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
            ></path>
            <path
              class="color-background"
              d="m467 181h-422c-24.814 0-45 20.186-45 45v60c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45v-60c0-24.814-20.186-45-45-45zm-392 90c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm242 0h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
            ></path>
            <path
              class="color-background"
              d="m467 362h-422c-24.814 0-45 20.186-45 45v60c0 24.814 20.186 45 45 45h422c24.814 0 45-20.186 45-45v-60c0-24.814-20.186-45-45-45zm-392 90c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 0c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm242 0h-120c-8.291 0-15-6.709-15-15s6.709-15 15-15h120c8.291 0 15 6.709 15 15s-6.709 15-15 15z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
    fill: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
