<template>
  <div class="container-fluid mt-3 py-4 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="font-weight-bolder">
              <a href="/admin"
                ><i class="fa fa-arrow-left"></i> Back to Admin</a
              >
            </p>
            <p>Administration / All Users</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-5 mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row p-0">
              <div class="col-8">
                <h5>
                  <i class="fa fa-info-circle"></i> Total
                  {{ users.length }} users. | Displaying
                  {{ filterUsers().length }}
                </h5>

                <button
                  class="btn btn-outline-primary my-4"
                  data-bs-toggle="modal"
                  data-bs-target="#massActivationModal"
                >
                  <i class="fa fa-warning"></i> Mass Activations
                </button>
              </div>
              <div class="col-4">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="onlyActive"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Only Active
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    v-model="onlyInActive"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Only Inactive
                  </label>
                </div>

                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="filterValue"
                    placeholder="Filter by name or email..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Active</th>

                    <th>Created At</th>

                    <th>Manage Credits</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <UserTableRow
                    v-for="user in filterUsers()"
                    :key="user._id"
                    :user="user"
                  />
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mass activation modal -->

  <div
    class="modal"
    id="massActivationModal"
    aria-labelledby="massActivationModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Mass Activations</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body pt-3 text-sm">
          <div class="row">
            <div class="col-12">
              <label for="csvUpload" class="form-label">Upload CSV</label>
              <br />

              <!-- CSV Uplaoder -->
              <vue-csv-import
                v-model="csv"
                :fields="{
                  email: { required: false, label: 'email' },
                }"
              >
                <vue-csv-toggle-headers></vue-csv-toggle-headers>
                <br />
                <vue-csv-errors></vue-csv-errors>
                <br />
                <vue-csv-input></vue-csv-input>
                <br />
                <vue-csv-map></vue-csv-map>
              </vue-csv-import>
              <br />
              <p class="text-primary mb-2">
                <i class="fa fa-info-circle"></i> Found
                {{ filterValidEmails(csv).length }} valid emails in the CSV.
              </p>
              <br />
              <label for="credits" class="form-label mt-2"
                >Credits in $XAP</label
              >
              <input
                class="form-control form-control-lg mb-3"
                id="credits"
                type="number"
                name="credits"
                v-model="credits"
              />

              <label for="expiresOn" class="form-label"
                >Expires On | Current Value: {{ formatDate(expiresOn) }} =
                Javascript Date.now() + 365*24*60*60*1000 ms</label
              >
              <input
                class="form-control form-control-lg mb-3"
                id="expiresOn"
                type="number"
                name="expiresOn"
                v-model="expiresOn"
              />

              <label for="creditDescription" class="form-label"
                >Credit Description</label
              >
              <input
                class="form-control form-control-lg mb-3"
                id="creditDescription"
                type="text"
                name="creditDescription"
                v-model="creditDescription"
                placeholder="Description of the credits..."
              />

              <label for="userNote" class="form-label"
                >Note to be added for the users (for our own use)</label
              >
              <input
                class="form-control form-control-lg mb-3"
                id="userNote"
                type="text"
                name="userNote"
                placeholder="How was this user acquired?"
                v-model="userNote"
              />

              <button
                type="submit"
                class="btn btn-primary mt-4"
                id="submitFileButton"
                @click="massActivate()"
              >
                Mass Activate
              </button>
            </div>
            <hr class="mt-4" />
            <div class="col-12">
              <p class="font-weight-bolder">Response:</p>
              <vue-json-pretty :data="jsonResponse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import UserTableRow from "./components/UserTableRow.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import {
  VueCsvToggleHeaders,
  VueCsvSubmit,
  VueCsvMap,
  VueCsvInput,
  VueCsvErrors,
  VueCsvImport,
} from "vue-csv-import";
export default {
  name: "AdminUsers",
  components: {
    UserTableRow,
    VueCsvToggleHeaders,
    VueCsvSubmit,
    VueCsvMap,
    VueCsvInput,
    VueCsvErrors,
    VueCsvImport,
    VueJsonPretty,
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //fetch users
    axios
      .get(this.$store.getters["getServerURL"] + "/admin/getAllUsers", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        this.users = response.data;
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      users: [],
      filterValue: "",
      onlyActive: false,
      onlyInActive: false,
      csv: [],
      credits: 0,
      userNote: "",
      creditDescription: "",
      expiresOn: parseFloat(Date.now()) + 31536000000,
      jsonResponse: {},
    };
  },
  methods: {
    massActivate() {
      if (!confirm("Are you sure you want to mass active and add credits?")) {
        return;
      }

      this.jsonResponse = {};
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/massActivations",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          users: this.filterValidEmails(this.csv),
          credits: this.credits,
          expiresOn: this.expiresOn,
          creditDescription: this.creditDescription,
          userNote: this.userNote,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.jsonResponse = response.data;
        })
        .catch((err) => {
          console.log(err);
          this.jsonResponse = err.response.data;
        });
    },
    filterUsers() {
      let result = this.users;
      if (this.onlyActive) {
        result = result.filter((user) => user.active === 1);
      }
      if (this.onlyInActive) {
        result = result.filter((user) => user.active === 0);
      }
      if (!this.filterValue) return result;

      console.log(this.onlyActive);
      const filterValue = this.filterValue.toLowerCase();

      const filter = (event) =>
        event.name.toLowerCase().includes(filterValue) ||
        event.email.toLowerCase().includes(filterValue);

      return result.filter(filter);
    },
    filterValidEmails(objects) {
      // Regular expression to validate emails
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Filter objects with valid emails
      console.log(objects.filter((obj) => emailRegex.test(obj.email)));
      return objects.filter((obj) => emailRegex.test(obj.email));
    },
    formatDate(unixTimestamp) {
      var date = new Date(parseInt(unixTimestamp));
      return date.toLocaleDateString("en-IN");
    },
  },
};
</script>

<style>
.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  padding: 10px;
}

.quick-links li a:hover {
  color: initial;
}
.quick-links li:hover {
  background: #eee;
}
</style>
