<template>
  <div class="card mt-4">
    <div class="card-body p-0">
      <div v-if="invoices.length == 0" class="w-100 text-center p-5">
        <h2 class="fa fa-info-circle"></h2>
        <p>No invoices created yet.</p>
      </div>
      <div class="table-responsive w-100" v-else>
        <table class="table mb-0">
          <thead class="bg-primary text-white">
            <tr>
              <th>Invoice Month and year</th>
              <th>Egress</th>
              <th>Ingress</th>
              <th>Storage</th>
              <th>Billed</th>
              <th>Credits Used</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="invoice in invoices" :key="invoice._id">
              <td class="px-4">
                {{ getMonth(invoice.month) }}, {{ invoice.year }}
                <br />
                <span class="text-xs IDLabel">{{ invoice._id }}</span>
              </td>
              <td class="px-4">
                {{ formatBytes(invoice.dataEgress).num || "-" }}
                {{ formatBytes(invoice.dataEgress).suffix }}
              </td>
              <td class="px-4">
                {{ formatBytes(invoice.dataIngress).num || "-" }}
                {{ formatBytes(invoice.dataIngress).suffix }}
              </td>
              <td class="px-4">
                {{ formatBytes(invoice.dataStorage).num || "-" }}
                {{ formatBytes(invoice.dataStorage).suffix }}
              </td>
              <td class="px-4">
                {{ (invoice.payable / 1000000).toFixed(5) }}
                $XAP
              </td>
              <td class="px-4">
                {{ (invoice.creditsUsed / 1000000).toFixed(5) }}
                $XAP
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftButton from "@/components/SoftButton.vue";
export default {
  name: "InvoiceCard",
  components: {
    SoftButton,
  },
  props: {
    invoices: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    getMonth(numMonth) {
      switch (numMonth) {
        case 0:
          return "January";
        case 1:
          return "February";
        case 2:
          return "March";
        case 3:
          return "April";
        case 4:
          return "May";
        case 5:
          return "June";
        case 6:
          return "July";
        case 7:
          return "August";
        case 8:
          return "September";
        case 9:
          return "October";
        case 10:
          return "November";
        case 11:
          return "December";
      }
    },
    calculatePayable(dS, dSP, dT, dTP) {
      // console.log({ dS, dSP, dT, dTP });
      var cost = (dS / (1024 * 1024)) * dSP;
      cost += (dT / (1024 * 1024)) * dTP;
      cost = cost / 1000000;
      return cost.toFixed(8);
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
  },
};
</script>
