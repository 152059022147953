<template>
  <svg
    width="45px"
    viewBox="0 0 41 31"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>wifi</title>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-2319.000000, -291.000000)"
        :fill="fill"
        fill-rule="nonzero"
      >
        <g>
          <g>
            <path
              d="M454.968,160.667c-0.175-0.257-0.374-0.498-0.595-0.719C393.884,99.46,313.461,66.147,227.919,66.147
			c-85.537,0-165.953,33.306-226.439,93.788c-0.94,0.933-1.48,2.21-1.48,3.552c0,1.326,0.526,2.598,1.465,3.536l33.113,33.114
			c0.938,0.938,2.209,1.464,3.535,1.464c1.326,0,2.598-0.527,3.535-1.464c49.836-49.839,115.988-77.285,186.271-77.285
			c70.282,0,136.434,27.447,186.271,77.284c0.938,0.938,2.209,1.464,3.534,1.464c1.326,0,2.599-0.527,3.535-1.464l33.113-33.114
			c0.938-0.938,1.465-2.209,1.465-3.536C455.839,162.481,455.534,161.499,454.968,160.667z"
            ></path>
            <path
              d="M227.919,153.719c-62.056,0-120.461,24.231-164.458,68.229c-1.952,1.953-1.952,5.119,0.001,7.071l33.01,33.009
			c0.938,0.938,2.209,1.465,3.535,1.465c1.326,0,2.598-0.527,3.535-1.465c33.277-33.277,77.448-51.605,124.377-51.605
			c46.931,0,91.102,18.328,124.376,51.605c0.938,0.938,2.21,1.465,3.536,1.465s2.598-0.527,3.535-1.465l33.011-33.009
			c0.938-0.938,1.465-2.209,1.465-3.536c0-1.327-0.525-2.598-1.465-3.536C348.38,177.951,289.975,153.719,227.919,153.719z"
            ></path>
            <path
              d="M227.919,241.292c-38.701,0-75.126,15.11-102.564,42.549c-0.939,0.938-1.465,2.209-1.465,3.537
			c0,1.326,0.525,2.598,1.465,3.535l33.01,33.01c0.938,0.938,2.209,1.465,3.535,1.465s2.598-0.527,3.535-1.465
			c16.719-16.719,38.909-25.926,62.484-25.926s45.767,9.209,62.485,25.926c0.938,0.938,2.209,1.465,3.534,1.465
			s2.598-0.527,3.535-1.465l33.01-33.01c1.952-1.953,1.952-5.119,0-7.07C303.046,256.402,266.621,241.292,227.919,241.292z"
            ></path>
            <path
              d="M227.919,334.083c-13.521,0-26.229,5.264-35.784,14.822c-1.952,1.953-1.952,5.118,0.001,7.07l32.248,32.25
			c0.938,0.938,2.209,1.465,3.535,1.465s2.599-0.527,3.535-1.465l32.248-32.25c1.952-1.953,1.952-5.118,0-7.071
			C254.146,339.347,241.438,334.083,227.919,334.083z"
            ></path>
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
    fill: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
