<template>
  <navbar btn-background="bg-gradient-primary" />
  <div
    class="pt-5 page-header align-items-start min-vh-40 pb-11"
    :style="{
      'background-image': 'url(' + BGGrid + ')',
      'background-size': 'cover',
      'background-position': 'top',
    }"
  >
    <span class="mask opacity-9" style="background-color: #070707"></span>
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <h1 class="mt-5 pt-5 mb-2 text-white">👋 Hi there!</h1>
          <p class="text-white text-lead">
            Register for Exa Protocol Management Console with your email -
            normie friendly signup!
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10">
      <div class="col-md-4">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5><i class="fa fa-user"></i> Register with email</h5>
          </div>
          <div class="card-body">
            <form role="form" @submit.prevent="signUp()">
              <div class="mb-3">
                <input
                  id="name"
                  type="text"
                  placeholder="Enter your company name"
                  aria-label="Name"
                  name="name"
                  class="form-control"
                  autocomplete="name"
                  v-model="name"
                  required
                />
              </div>
              <div class="mb-3">
                <input
                  id="email"
                  type="email"
                  placeholder="Your work email"
                  aria-label="Email"
                  class="form-control"
                  autocomplete="username"
                  name="email"
                  v-model="email"
                  required
                />
              </div>
              <div class="mb-3">
                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  aria-label="Password"
                  class="form-control"
                  name="password"
                  autocomplete="new-password"
                  maxlength="32"
                  minlength="8"
                  v-model="password"
                  required
                />
              </div>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="flexCheckDefault"
                  v-model="tosConsent"
                />
                <label class="form-check-label" for="flexCheckDefault">
                  I agree with the
                  <a
                    href="/privacy"
                    class="text-dark font-weight-bolder"
                    target="_blank"
                    >Privacy Policy</a
                  >
                  &
                  <a
                    href="/tos"
                    class="text-dark font-weight-bolder"
                    target="_blank"
                    >Terms of Use</a
                  >
                </label>
              </div>

              <div class="d-flex justify-content-center mb-3 mt-4">
                <RecaptchaV2
                  @widget-id="handleWidgetId"
                  @error-callback="handleErrorCalback"
                  @expired-callback="handleExpiredCallback"
                  @load-callback="handleLoadCallback"
                />
              </div>

              <ul v-if="validationMessages.length > 0" class="my-3">
                <li
                  v-for="message in validationMessages"
                  :key="message"
                  class="text-danger text-sm"
                >
                  {{ message }}
                </li>
              </ul>
              <div class="text-center">
                <soft-button
                  color="dark"
                  full-width
                  variant="gradient"
                  class="my-4 mb-2"
                  type="submit"
                  >Sign up</soft-button
                >
              </div>
              <p class="text-sm mt-3 mb-0">
                Already have an account?
                <router-link
                  :to="{ name: 'Signin Basic' }"
                  class="text-dark font-weight-bolder"
                >
                  Sign in
                </router-link>
              </p>
            </form>

            <Toast />
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card h-100">
          <div class="card-body">
            <div v-if="referredBy" class="d-flex h-100">
              <div class="text-center p-4 align-self-center mx-auto">
                <Avatar :size="80" variant="beam" :name="referredBy.name" />
                <h3 class="mt-3">You got referred! 🎉</h3>
                <p class="text-lg">
                  <b>{{ referredBy.name }}</b> invited you to join Exa Protocol.
                </p>
                <hr class="horizontal dark" />

                <p>
                  You both will earn <b>2500 compute points</b> after you
                  successfuly rent your machine!
                </p>

                <button class="btn btn-link mt-4" @click="removeReferral()">
                  I don't want this referral
                </button>
              </div>
            </div>
            <iframe
              v-else
              class="signup-anim"
              src="https://lottie.host/embed/66f30f5c-635d-4ea1-901d-69a9c21b5e36/aUJCbgnL6V.json"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import Navbar from "@/components/PageLayout/Navbar.vue";
import AppFooter from "@/components/PageLayout/Footer.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";

import { mapMutations } from "vuex";
import axios from "axios";
import Toast from "primevue/toast";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import Brands from "../../home/componentrs/Brands.vue";
import BGGrid from "@/assets/img/bg-grid.svg";
import Avatar from "vue-boring-avatars";

export default {
  name: "SignupBasic",
  components: {
    Navbar,
    AppFooter,
    SoftCheckbox,
    SoftButton,
    RecaptchaV2,
    Toast,
    Brands,
    Avatar,
  },
  data() {
    return {
      BGGrid: BGGrid,
      captchaResponse: "",
      captchaResponseMessage: "",
      name: "",
      email: "",
      password: "",
      tosConsent: false,
      validationMessages: [],
      emailValidationError: false,
      passwordValidationError: false,
      widgetID: "",
      referredBy: null,
    };
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  async mounted() {
    if (localStorage.getItem("jwt")) {
      this.$router.push("/dashboard");
    }
    localStorage.removeItem("referralName");

    if (this.$route.query.referredBy || localStorage.getItem("referredBy")) {
      try {
        var response = await axios.get(
          this.$store.getters["getServerURL"] +
            "/referral/validateReferral/" +
            (this.$route.query.referredBy
              ? this.$route.query.referredBy
              : localStorage.getItem("referredBy"))
        );
        console.log(response);
        this.referredBy = response.data;
        localStorage.setItem(
          "referredBy",
          this.$route.query.referredBy
            ? this.$route.query.referredBy
            : localStorage.getItem("referredBy")
        ); //set referredBy to local storage
        this.$confetti.start();
        localStorage.setItem("referralName", response.data.name);
        setTimeout(() => {
          this.$confetti.stop();
        }, 2000);
      } catch (err) {
        console.log(err);
        localStorage.removeItem("referredBy");
        localStorage.removeItem("referralName");
        this.$toast.add({
          severity: "info",
          summary: "Attention!",
          detail: "Invalid referral code!",
          life: 3000,
        });
        return;
      }

      return;
    }
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    validateEmail() {
      var regex = /^[a-zA-Z0-9_.+\-]+[\x40][a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$/;
      if (!regex.test(this.email)) {
        console.log("Email is invalid.");
        this.validationMessages.push("Email is invalid.");
        this.emailValidationError = true;
      } else {
        this.emailValidationError = false;
      }
    },
    validatePassword() {
      var passwordVal = true;
      if (this.password.length < 8 || this.password.length > 32)
        passwordVal = false;

      if (!passwordVal) {
        this.validationMessages.push(
          "Password is invalid. \nPassword must be between 8 and 32 characters."
        );
        this.passwordValidationError = true;
      } else {
        this.passwordValidationError = false;
      }
    },
    validateTosConsent() {
      if (!this.tosConsent) {
        this.validationMessages.push("You must agree to terms and conditions.");
      }
    },
    signUp() {
      this.validationMessages = [];
      this.emailValidationError = false;
      this.passwordValidationError = false;

      this.validateEmail();
      this.validatePassword();
      this.validateTosConsent();

      if (
        this.emailValidationError ||
        this.passwordValidationError ||
        !this.tosConsent
      ) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/auth/signup",
        data: {
          name: this.name,
          email: this.email,
          password: this.password,
          gRecaptchaResponse: this.captchaResponseMessage,
          referredBy: this.referredBy ? this.referredBy.referralCode : null,
        },
      })
        .then((response) => {
          console.log(response);
          localStorage.removeItem("referredBy");
          this.$router.push("/authentication/signin/?register=true");
        })
        .catch((error) => {
          // console.log(error);
          handleReset(this.widgetID);
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
    removeReferral() {
      localStorage.removeItem("referredBy");
      localStorage.removeItem("referralName");
      window.location.href = window.location.origin + window.location.pathname;
    },
  },
};
</script>

<style>
.signup-anim {
  height: 90%;
  width: 100%;
}
</style>
