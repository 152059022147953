<template>
  <aside
    id="sidenav-main"
    class="overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs bg-white"
    style="z-index: 1000"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/">
        <img
          :src="require('@/assets/img/logo.png')"
          class="navbar-brand-img"
          alt="main_logo"
        />
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :card-bg="customClass" />
  </aside>
</template>
<script>
import SidenavList from "./SidenavList.vue";

import { mapState } from "vuex";
export default {
  name: "Index",
  components: {
    SidenavList,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["isRTL"]),
  },
};
</script>
<style>
#sidenav-main {
  z-index: 10;
}
.navbar-vertical .navbar-brand > img,
.navbar-vertical .navbar-brand-img {
  max-width: 100%;
  max-height: 3rem !important;
}
</style>
