<template>
  <div
    class="card p-0 h-100 highlight-card"
    v-tooltip.left="{
      value:
        'Deployment will be avaiable soon. We are onboarding compute nodes at the moment.',
      autoHide: false,
    }"
  >
    <div class="card-body p-0 p-3">
      <div class="row">
        <div class="col-3 h-100 d-flex justify-content-center">
          <Avatar :size="80" variant="marble" :name="model.modelAvatar" />
        </div>
        <div class="col-9">
          <h6>
            {{ model.modelName }}
            <span
              class="badge badge-dark badge-sm text-white"
              v-if="model.modelPrecision"
              >{{ model.modelPrecision }}</span
            >
          </h6>

          <p class="text-xs pb-0 mb-0">
            {{ model.modelDescription }}
          </p>
          <button
            class="btn btn-outline-dark btn-xs px-2 mb-0 mt-3 d-block"
            disabled
          >
            Deploy Agent
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-boring-avatars";

export default {
  name: "AIAgentCard",
  components: {
    Avatar,
  },
  props: {
    model: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style></style>
