<template>
  <!-- Bucket -->

  <svg
    :height="height"
    viewBox="0 0 64 64"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    id="fi_9477897"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-2319.000000, -291.000000)"
        :fill="fill"
        fill-rule="nonzero"
      >
        <g transform="translate(1716.000000, 291.000000)">
          <g transform="translate(603.000000, 0.000000)">
            <path
              class="color-background"
              d="m55.21 18.72h-5.56a17.71 17.71 0 0 0 -35.3 0h-5.56a1.5 1.5 0 1 0 0 3h.58l5.14 38.48a1.5 1.5 0 0 0 1.49 1.3h32a1.5 1.5 0 0 0 1.48-1.3l5.14-38.48h.58a1.5 1.5 0 1 0 0-3zm-23.21-13.22a14.76 14.76 0 0 1 14.65 13.22h-29.3a14.76 14.76 0 0 1 14.65-13.22z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
    fill: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
