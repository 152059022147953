<template>
  <div
    class="card fade-it shadow-lg h-100"
    data-aos="fade-up"
    data-aos-once="true"
  >
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="p-2 avatar avatar-xl bg-gradient-dark border-radius-md">
          <Avatar :size="80" variant="pixel" :name="app.appName" />
        </div>
        <div class="my-auto ms-3">
          <h6>
            {{ app.appName }} -

            <span v-if="app.active == 0" class="badge badge-warning badge-sm"
              >Inactive</span
            >
            <span v-if="app.active == 1" class="badge badge-primary badge-sm"
              >Active</span
            >
            <span v-if="app.active == 2" class="badge badge-warning badge-sm"
              >Suspended</span
            >

            <span v-if="app.deleted" class="badge badge-danger badge-sm"
              >Deleted</span
            >
          </h6>
          <p class="text-xs text-primary mb-0">
            <span class="font-weight-bold">ID:</span> {{ app._id }}
          </p>
          <p class="text-xs text-muted mt-0">
            <span class="font-weight-bold">Identifier:</span>
            {{ app.appIdentifier }}
          </p>
        </div>
        <div class="ms-auto">
          <div class="dropdown">
            <button
              id="navbarDropdownMenuLink"
              class="btn btn-link text-secondary ps-0 pe-2"
              :class="{ show: showMenu }"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="text-lg fa fa-cog"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
              :class="{ show: showMenu }"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                class="dropdown-item border-radius-md text-danger"
                @click="updateApp(2)"
                >Suspend App
              </a>
              <a
                class="dropdown-item border-radius-md text-success"
                @click="updateApp(1)"
                >De-Suspend App
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <p class="text-sm">
        <span class="font-weight-bolder">Description:</span>
        {{ app.appDescription }}
      </p>
      <p class="badge badge-dark badge-sm m-0" v-if="app.domainSSLGenerated">
        <i class="fa fa-globe text-white"></i> &nbsp;<a
          :href="'https://' + app.domainName"
          target="_blank"
          >{{ app.domainName }}</a
        >
      </p>
      <p
        class="badge badge-dark badge-sm text-white mx-1 my-1"
        v-if="app.domainSSLGenerated"
      >
        SSL Gen Time: {{ getDate(parseInt(app.domainSSLGenerationTime)) }}
      </p>
      <p
        class="badge badge-dark badge-sm text-white m-0"
        v-if="app.domainOwnershipVerified"
      >
        Domain Verification:
        {{ getDate(parseInt(app.domainOwnershipVerificationTime)) }}
      </p>
      <hr class="horizontal dark" />
      <div class="row">
        <div class="col-6">
          <p class="mb-0 text-xs text-secondary">Last Updated</p>
          <h6 class="mb-0 text-sm">{{ getDate(parseFloat(app.updatedOn)) }}</h6>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <button
            class="btn btn-link btn-sm"
            @click="this.$router.push('/buckets/filemanager/' + app._id)"
          >
            View Bucket
          </button>
        </div>
      </div>
    </div>
  </div>

  <Toast />
</template>

<script>
import axios from "axios";
import Avatar from "vue-boring-avatars";
import Toast from "primevue/toast";
export default {
  name: "AppCard",
  components: {
    Avatar,
    Toast,
  },
  props: {
    app: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  methods: {
    getDate(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var finetime = a.getHours() + ":" + a.getMinutes() + ":" + a.getSeconds();

      var time = date + " " + month + " " + year + " " + finetime;
      return time;
    },

    updateApp(activeState) {
      if (
        !confirm(
          "Are you sure you want to change the active state to: " +
            activeState +
            "\n App ID: " +
            this.app._id +
            " | App Name: " +
            this.app.appName
        )
      ) {
        return;
      }
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/updateApp",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          active: activeState,
          appID: this.app._id,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "App updated! Reloading page...",
            life: 2000,
          });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Error while updating the app.",
            life: 5000,
          });
        });
    },
  },
};
</script>

<style>
.w-30-rem {
  width: 30rem !important;
}
</style>
