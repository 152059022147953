<template>
  <div class="card highlight-card mb-4" data-aos="fade-up">
    <div class="px-3 py-4 card-body text-center">
      <Avatar
        :size="80"
        variant="sunset"
        :name="appName"
        class="mx-auto d-block"
      />
      <hr class="my-4 horizontal dark" />
      <span v-if="active == 0" class="badge badge-warning badge-sm"
        >Inactive</span
      >
      <span v-if="active == 1" class="badge badge-primary badge-sm"
        >Active</span
      >
      <span v-if="active == 2" class="badge badge-danger badge-sm"
        >Suspended</span
      >
      <h6 class="mb-0 text-center">{{ appName }}</h6>

      <hr class="my-2 horizontal dark" />
      <a
        class="btn btn-link"
        @click="this.$router.push('/compute/collection/' + appId)"
        >View Collection</a
      >
    </div>
  </div>
</template>

<script>
import Avatar from "vue-boring-avatars";

export default {
  name: "ComputeCard",
  components: {
    Avatar,
  },
  props: {
    appName: {
      type: String,
      required: true,
    },
    active: {
      type: Number,
      default: 1,
    },
    appId: {
      type: String,
    },
    appIndentifier: {
      type: String,
      default: "",
    },
    appDescription: {
      type: String,
      default: "",
    },
  },
};
</script>

<style></style>
