<template>
  <div class="container-fluid mt-3 py-4 bg-gradient-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="font-weight-bolder">
              <a href="/admin/users"
                ><i class="fa fa-arrow-left"></i> Back to All Users</a
              >
            </p>

            <p>
              Administration / View User - {{ this.$route.params.userID }} |
              {{ completeProfile.user.email }}
            </p>

            <p>Org Name: {{ completeProfile.org.orgName || "-" }}</p>
            <p>Org About: {{ completeProfile.org.orgAbout || "-" }}</p>
            <p>
              Address: {{ completeProfile.org.orgAddress || "-" }},
              {{ completeProfile.org.orgCountry || "-" }}
            </p>
            <p class="badge badge-success"></p>
            <a
              class="btn btn-primary"
              :href="'/admin/users/manage-credits/' + this.$route.params.userID"
              >Manage Credits</a
            >
            <button
              class="btn btn-danger mx-2"
              v-if="completeProfile.user.active == 1"
              @click="updateUser(0)"
            >
              Deactivate user
            </button>
            <button class="btn btn-success mx-2" v-else @click="updateUser(1)">
              Activate User
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Apps</p>
              </div>
              <div class="col-md-4 mt-4" v-for="app in apps" :key="app._id">
                <AppCardAdmin :app="app" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-5 mt-4">
      <div class="col-md-12" data-aos="fade-up">
        <div class="card">
          <div class="card-body py-4">
            <div class="row">
              <div class="col-md-12">
                <p>All Invoices</p>
              </div>
              <InvoiceCard :invoices="invoices" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Toast></Toast>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import AppCardAdmin from "./components/AppCardAdmin.vue";
import InvoiceCard from "../../../pages/Account/components/InvoiceCard.vue";
import Toast from "primevue/toast";

export default {
  name: "ViewUser",
  components: {
    AppCardAdmin,
    InvoiceCard,
    Toast,
  },
  data() {
    return {
      invoices: [],
      apps: [],
      completeProfile: {
        user: {
          email: "",
        },
        org: {},
      },
    };
  },
  mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //getUser
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getUser/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.completeProfile = response.data;

        console.log(this.completeProfile);
      })
      .catch((err) => {
        console.log(err);
      });

    //fetch apps
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getAllApps/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.apps = response.data;
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });

    //fetch invoices
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getAllInvoices/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.invoices = response.data;
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    updateUser(state) {
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/updateUser",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          userID: this.completeProfile.user._id,
          active: state,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: this.completeProfile.user.email + " Updated!",
            life: 2000,
          });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
