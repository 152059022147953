<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12">
        <div class="card">
          <div class="ribbon mt-5 bg-info" v-if="isSuper">
            <i class="fa fa-user-secret"></i>
          </div>
          <div class="ribbon bg-primary" v-if="app.active == 1 && !appLoading">
            Active
          </div>
          <div class="ribbon bg-danger" v-if="app.active == 2 && !appLoading">
            Suspended
          </div>

          <div class="card-body px-4">
            <div class="row">
              <div class="col-10">
                <Skeleton
                  width="25rem"
                  class="mb-2"
                  v-if="appLoading"
                ></Skeleton>
                <template v-else>
                  <h3>{{ app.appName || "-" }} | Compute Collection</h3>
                  <p class="text-sm">
                    From this page, you can launch powerful virtual machines
                    (VMs) for dedicated tasks or execute Omega functions for
                    instant, on-demand compute—all powered by our decentralized
                    infrastructure.
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-lg-9">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active text-dark text-bold"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              Ω | Omega Functions
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link text-dark text-bold"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              Virtual Machines (VMs)
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <p class="text-sm">
                      Omega functions provide on-demand execution of compute
                      tasks without managing VMs. From AI/ML inference to 3D
                      rendering and data analysis, Omega delivers scalable,
                      cost-efficient compute power instantly, leveraging
                      ExaCompute’s decentralized infrastructure.
                    </p>
                    <hr class="horizontal dark" />
                  </div>
                  <div class="col-12 text-center my-7">
                    <font-awesome-icon
                      icon="fa-solid fa-hashtag"
                      class="fa-2x"
                    />
                    <h4>Coming Soon!</h4>
                    <p>
                      Omega functions will be avaialable soon! <br />We are
                      currently onboarding compute nodes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <p class="text-sm">
                      ExaCompute’s VMs provide customizable environments to run
                      any OS and software, making them ideal for web services,
                      applications, and tasks requiring dedicated hardware
                      resources. The VMs can be accessed via SSH.
                    </p>
                  </div>
                  <hr class="horizontal dark" />
                  <div class="col-12 text-center my-7">
                    <font-awesome-icon
                      icon="fa-solid fa-hashtag"
                      class="fa-2x"
                    />
                    <h4>Coming Soon!</h4>
                    <p>
                      Virtual Machines will be avaialable soon! <br />We are
                      currently onboarding compute nodes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <div class="card">
          <div class="card-header pb-2 my-0 border-bottom">
            <h6><i class="fa fa-info-circle"></i> ExaCompute Timeline</h6>
          </div>
          <div class="card-body">
            <p class="text-sm">
              <i class="fa fa-circle-dot text-success fa-beat"></i> Onboarding
              compute nodes.
            </p>
            <p class="text-sm">
              <i class="fa fa-circle-dot text-light"></i> Launch Omega Functions
              (Q1).
            </p>
            <p class="text-sm">
              <i class="fa fa-circle-dot text-light"></i> Launch VMs (Q1).
            </p>
          </div>
        </div>
      </div>

      <div class="col-lg-12 mt-4">
        <div class="card">
          <div class="card-header mb-0">
            <h5 class="mb-0">Built to run anything...</h5>
          </div>
          <div class="card-body mt-0 pt-0 pb-0">
            <img
              :src="require('@/assets/img/any_soft_compute.png')"
              alt=""
              class="img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import axios from "axios";
import Toast from "primevue/toast";
import Skeleton from "primevue/skeleton";

export default {
  name: "ComputeCollection",
  components: {
    Toast,
    Skeleton,
  },
  async mounted() {
    this.$store.state.isAbsolute = false;
    this.$store.state.isNavFixed = false;
    setNavPills();

    //get app
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/org/apps/getApp/" +
          this.$route.params.id,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((resp) => {
        this.app = resp.data;
        this.isApp = true;
        this.appLoading = false;
      })
      .catch((err) => {
        //    console.log(err);
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Unable to fetch app.",
          life: 3000,
        });
      });
  },
  data() {
    return {
      appLoading: true,
      fileCount: 0,
      app: {},
      isApp: false,
      loading: true,
      isSuper: false,
    };
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },

    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "Copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
  },
};
</script>

<style>
/* HTML: <div class="ribbon">Your text content</div> */
.ribbon {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.ribbon {
  --f: 0.5em; /* control the folded part*/
  --r: 0.8em; /* control the ribbon shape */

  position: absolute;
  top: 20px;
  right: calc(-1 * var(--f));
  padding-inline: 2em;

  line-height: 1.8;
  /* background: #1f54ff; */
  border-bottom: var(--f) solid #0005;
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}

.bg-danger-modal-opacity-05 {
  background: rgba(64, 0, 0, 0.8);
}

.p-tabview-tablist-item-active {
  border-color: #0004aa !important;
}
</style>
