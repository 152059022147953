<template>
  <tr v-for="file in files" :key="file._id" class="fade-it">
    <td
      class="px-4 truncate"
      scope="row"
      :title="file.originalName"
      style="width: 15%"
    >
      <i :class="mimeTypeToIcon(file.fileDetails.mimetype)"></i>
      <span
        class="font-weight-bold px-2"
        v-tooltip.bottom="{ value: file.originalName, autoHide: false }"
        >{{ file.originalName }}</span
      >
      <br />

      <span class="badge badge-sm badge-light">{{ file._id }}</span>
      <br v-if="file.overwriteProtection" />
      <span
        v-if="file.overwriteProtection"
        v-tooltip.bottom="{
          value:
            'This file is safeguarded against accidental overwrites, as it is managed by ExaCompute.',
          autoHide: false,
        }"
        class="badge-sm badge badge-success"
        >OVERWRITE_PROTECTION_ENABLED</span
      >
    </td>
    <td
      scope="row"
      class="px-4"
      style="border-left: 1px solid #ddd; width: 15%"
    >
      <code
        class="text-dark"
        v-tooltip.bottom="{ value: file.virtualPath, autoHide: false }"
        >{{ file.virtualPath }}</code
      >
    </td>

    <td scope="row" class="px-4 text-sm" style="width: 5%">
      {{ formatBytes(file.fileDetails.size, 2) }}
    </td>
    <td scope="row" class="px-4" style="width: 5%">
      <span class="badge badge-primary badge-sm">{{ file.isPublic }}</span>
    </td>

    <td
      scope="row"
      class="px-4 text-sm"
      style="width: 15%"
      :title="file.uploadedOn"
    >
      {{ file.uploadedOn }}
    </td>
    <td style="width: 5%; border-left: 1px solid #ddd">
      <div class="form-check form-switch">
        <input
          class="form-check-input mx-auto"
          type="checkbox"
          id="flexSwitchCheckDefault"
          :checked="file.enableCDN"
          v-on:input="this.$parent.toggleCDN(file._id, file.enableCDN)"
        />
      </div>
    </td>
    <td class="px-4" style="width: 5%">
      <template v-if="domainSSLGenerated">
        <button
          class="btn btn-link btn-sm mb-0 px-0"
          @click="this.$parent.copyText(replaceDomain(file.publicLink))"
        >
          <span class="fa fa-copy"></span> Domain URL
        </button>
        <br />
        <p class="text-xs text-dark">
          <span
            @click="this.$parent.copyText(file.publicLink)"
            class="text-primary cursor-pointer"
            ><span class="fa fa-copy"></span> CDN URL</span
          >
        </p>
      </template>
      <template v-else>
        <button
          class="btn btn-link btn-sm mb-0 px-0"
          @click="this.$parent.copyText(file.publicLink)"
        >
          <span class="fa fa-copy"></span> CDN URL
        </button>
      </template>
    </td>
    <td scope="row" class="px-4" style="width: 5%">
      <button
        class="btn btn-link text-danger mb-0 px-0"
        @click="
          this.$parent.deleteFile(file._id, file.originalName, file.virtualPath)
        "
      >
        <font-awesome-icon icon="fa-solid fa-trash" /> Delete
      </button>
    </td>
  </tr>
</template>
<script>
export default {
  name: "FileRow",
  props: {
    files: {
      type: Array,
    },
    domainName: {
      type: String,
      default: null,
    },
    domainSSLGenerated: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    mimeTypeToIcon(mimeType) {
      switch (mimeType) {
        case "application/pdf":
          return "fa fa-file-pdf";
        case "image/jpeg":
          return "fa fa-file-image ";
        case "image/jpg":
          return "fa fa-file-image ";
        case "image/svg+xml":
          return "fa fa-shapes ";
        case "image/png":
          return "fa fa-file-image ";
        case "image/gif":
          return "fa fa-file-image ";
        case "text/html":
          return "fa fa-html5";
        case "text/css":
          return "fa fa-css3";
        case "application/x-javascript":
          return "fa fa-code";
        default:
          return "fa fa-file";
      }
    },
    RevealHiddenOverflow(id) {
      console.info("Called");
      const vdfs = document.getElementById(id);
      vdfs.classList.toggle("truncate");
    },
    replaceDomain(url) {
      try {
        // Parse the URL
        const urlObj = new URL(url);

        // Replace the domain and subdomain with "example.com"
        urlObj.hostname = this.domainName;

        // Return the modified URL
        return urlObj.toString();
      } catch (error) {
        console.error("Invalid URL:", error);
        return null; // Return null for invalid URLs
      }
    },
  },
};
</script>
<style scoped>
.IDLabel {
  font-size: 10px;
}
</style>
