<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          is-blur="blur blur-rounded my-3 py-2 start-0 end-0 mx-4 shadow"
          btn-background="bg-gradient-success"
          :dark-mode="true"
        />
      </div>
    </div>
  </div>

  <section
    class="min-vh-80"
    :style="{
      'background-image': 'url(' + BGFeatures + ')',
      'background-repeat': 'no-repeat',
      'background-size': 'contain',
      'background-position': 'center',
    }"
  >
    <div class="container">
      <div class="row">
        <div class="mx-auto col-lg-5 col-md-7">
          <div class="mb-4 card z-index-0 mt-sm-12 mt-9" v-if="verifyToken">
            <div class="card-body text-center py-6">
              <h4 class="mb-1 font-weight-bolder">
                <i class="fa fa-circle-check text-success"></i> Account
                Activated!
              </h4>
              <p class="mb-0">
                You can now sign in to your account. <br />
                <a
                  href="/authentication/signin"
                  class="btn btn-primary mt-4 mb-0"
                  >Sign In</a
                >
              </p>
            </div>
          </div>

          <div class="mb-4 card z-index-0 mt-sm-12 mt-9 py-6" v-else>
            <div class="card-body text-center">
              <h4 class="mb-1 font-weight-bolder">
                <i class="fa fa-warning text-danger"></i> Activation token is
                invalid.
              </h4>
              <p class="mb-0">
                Please use the activation link sent to your email.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer />
</template>

<script>
import Navbar from "@/examples/PageLayout/Navbar.vue";
import AppFooter from "@/examples/PageLayout/Footer.vue";
const body = document.getElementsByTagName("body")[0];
import Toast from "primevue/toast";
import axios from "axios";

import BGGrid from "@/assets/img/bg-grid.svg";

import BGFeatures from "@/assets/img/bg-features.png";

import { mapMutations } from "vuex";
export default {
  name: "ActivationBasic",
  components: {
    Navbar,
    AppFooter,
    Toast,
    BGGrid,
    BGFeatures,
  },
  data() {
    return {
      verifyToken: false,
      token: "",
    };
  },
  async mounted() {
    if (localStorage.getItem("jwt")) {
      this.$router.push("/dashboard");
      return;
    }

    if (this.$route.query.token) {
      this.token = this.$route.query.token;
      try {
        var response = await axios.post(
          this.$store.getters["getServerURL"] +
            "/auth/activateUser/" +
            this.$route.query.token
        );
      } catch (err) {
        console.log(err);
        this.verifyToken = false;
        return;
      }

      console.log(response);
      this.verifyToken = true;
      return;
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.add("bg-gray-200");
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
    body.classList.remove("bg-gray-200");
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),

    changePassword() {
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/auth/forgotPassword/resetToken/" +
          this.$route.query.token,
        data: {
          new_password: this.newPassword,
          confirm_new_password: this.confirmNewPassword,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((response) => {
          console.log(response);
          this.$router.push("/authentication/signin/?passwordChange=true");
        })
        .catch((error) => {
          console.log(error);
          handleReset(this.widgetID);
          this.captchaResponseMessage = "";
          this.captchaResponse = false;

          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: error.response.data.errors,
            life: 5000,
          });
        });
    },
  },
};
</script>
