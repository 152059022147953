<template>
  <div class="container-fluid mt-3 py-4 bg-dark">
    <div class="row mt-3">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <p class="font-weight-bolder">
              <a href="/admin/users"
                ><i class="fa fa-arrow-left"></i> Back to All Users</a
              >
            </p>
            <p>
              <a :href="'/admin/users/view/' + this.$route.params.userID"
                ><i class="fa fa-arrow-left"></i> Back to this User</a
              >
            </p>
            <p>Administration / Manage Credits</p>

            <p>
              Administration / View User - {{ this.$route.params.userID }} |
              {{ completeProfile.user.email }}
            </p>

            <p>Org Name: {{ completeProfile.org.orgName || "-" }}</p>
            <p>Org About: {{ completeProfile.org.orgAbout || "-" }}</p>
            <p>
              Address: {{ completeProfile.org.orgAddress || "-" }},
              {{ completeProfile.org.orgCountry || "-" }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-md-6">
        <CreditsCard :credits="credits" />
      </div>
      <div class="col-md-6">
        <div class="card mt-4">
          <div class="card-header">Add Credits</div>
          <div class="card-body">
            <form
              id="addCreditsForm"
              role="form"
              @submit.prevent="addCredits()"
            >
              <label for="fileDirectory" class="form-label"
                >Credits Description</label
              >
              <div class="input-group mb-3">
                <input
                  type="text"
                  name="creditDescription"
                  placeholder="Credits Decription"
                  class="form-control"
                  id="creditDescription"
                  v-model="creditDescription"
                  aria-label="Credit DEscription"
                  autocomplete="off"
                />
              </div>

              <label for="creditsInXAP" class="form-label"
                >Credits in $XAP</label
              >
              <div class="input-group mb-3">
                <input
                  type="number"
                  name="creditDescription"
                  placeholder="Credits in XAP (max. 10000)"
                  min="0"
                  max="10000"
                  class="form-control"
                  id="creditsInXAP"
                  v-model="creditsVal"
                  aria-label="Credits"
                  autocomplete="off"
                />
              </div>

              <label for="expiresOn" class="form-label"
                >Expires On (Linux TimeStamp in Milliseconds.) |
                <a
                  href="https://www.epochconverter.com/"
                  target="_blank"
                  class="text-primary"
                  >Get Time in Milliseconds &nbsp;<i
                    class="fa fa-external-link"
                  ></i
                ></a>
              </label>
              <div class="input-group mb-3">
                <input
                  type="number"
                  name="expiresOn"
                  placeholder="Credit expiration time"
                  min="0"
                  class="form-control"
                  id="expiresOn"
                  v-model="expiresOn"
                  aria-label="Credit expiration time"
                  autocomplete="off"
                />
              </div>
              <p class="text-sm">
                Expires on: {{ formatDate(expiresOn) }} (dd/mm/yyyy)
              </p>
              <button
                type="submit"
                class="btn btn-primary"
                id="addCreditsButton"
              >
                Add Credits
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>
</template>

<script>
import axios from "axios";
import CreditsCard from "../../pages/Account/components/CreditsCard.vue";
import Toast from "primevue/toast";
export default {
  name: "ManageCredits",
  components: {
    CreditsCard,
    Toast,
  },
  data() {
    return {
      completeProfile: {
        user: {
          email: "",
        },
        org: {},
      },
      credits: [],

      creditsVal: 0,
      creditDescription: "Accelerator Credits",
      expiresOn: parseFloat(Date.now()) + 31536000000,
    };
  },
  mounted() {
    //getUser
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getUser/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.completeProfile = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });

    //getAllCredits
    //getUser
    axios
      .get(
        this.$store.getters["getServerURL"] +
          "/admin/getCredits/" +
          this.$route.params.userID,
        {
          headers: { Authorization: localStorage.getItem("jwt") },
        }
      )
      .then((response) => {
        this.credits = response.data;
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    addCredits() {
      if (
        !confirm(
          "Are you sure you want to add credits?\n\n " +
            this.creditsVal +
            " $XAP\n" +
            this.creditDescription +
            "\n" +
            this.expiresOn +
            "\n" +
            "\nCredit deletion will require access to mongo db."
        )
      ) {
        return;
      }

      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/admin/addCredits",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          userID: this.$route.params.userID,
          description: this.creditDescription,
          credits: this.creditsVal,
          expiresOn: this.expiresOn,
        },
      })
        .then((response) => {
          console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Added Credits! Reloading...",
            life: 2000,
          });

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    formatDate(unixTimestamp) {
      var date = new Date(parseInt(unixTimestamp));
      return date.toLocaleDateString("en-IN");
    },
  },
};
</script>
