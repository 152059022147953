<template>
  <div class="container mt-3 pt-2 dashboard-container-fluid">
    <div class="row mt-3">
      <div class="col-md-9">
        <h3 class="font-weight-bolder">
          <i class="fa fa-server"></i> Rent your machine
        </h3>
        <p class="text-sm">
          From this page, you can manage your connected machines on ExaCompute.
          Your earnings from sharing compute and referrals are also lsited here
          (per UTC timezone).
        </p>
      </div>

      <div class="col-md-3 d-flex align-items-center justify-content-end">
        <button
          class="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#addMachine"
        >
          + Add Machine
        </button>
      </div>
    </div>

    <div class="row mt-4">
      <!-- Earnings -->
      <div class="col-lg-8">
        <div class="row">
          <div class="col-md-4">
            <default-counter-card
              :count="machines.length"
              suffix=""
              shadow="no-shadow"
              description="Machine limit: No Limit"
              color="white"
              cardColor="primary"
              title="Your Total Machines"
            />
          </div>

          <div class="col-md-4">
            <default-counter-card
              :count="earnings.computeEarnings + earnings.referralEarnings"
              suffix=""
              description="Exa Compute Points"
              shadow="no-shadow"
              color="white"
              cardColor="primary"
              title="Lifetime Earnings"
            />
          </div>
          <div class="col-md-4">
            <default-counter-card
              :count="earnings.todaysEarnings"
              suffix=""
              description="Exa Compute Points"
              shadow="no-shadow"
              color="white"
              cardColor="primary"
              title="Today's Earnings"
            />
          </div>
        </div>
      </div>
      <!-- Tier -->
      <div class="col-lg-4">
        <div class="card h-100">
          <div class="card-body text-center py-3">
            <h6 class="mt-0 pt-0">Connection Status</h6>
            <h6
              class="text-success mt-0 my-3 py-1 mx-auto"
              style="border: 1px dashed green; width: 50%"
              v-if="deviceOnline"
            >
              <i class="fa fa-circle-dot fa-beat"></i>
              Online
            </h6>
            <h6
              class="text-muted mt-0 my-3 py-1 mx-auto"
              style="border: 1px dashed gray; width: 50%"
              v-else
            >
              <i class="fa fa-circle-dot"></i>
              Offline
            </h6>

            <p class="py-0 my-0 mt-2 text-sm">
              Online, if any one of your machine is online.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Earnings -->
    <div class="row mt-4 pt-4">
      <div class="col-lg-12">
        <h5 class="mb-1">Earnings Overtime (Last 30 days)</h5>
        <p class="mb-0 py-0 text-sm mb-4">
          Earnings are updated after polling data from various services and can
          take upto 12 hrs to reflect.
        </p>
        <Chart
          v-if="earnings.computeEarnings || earnings.referralEarnings"
          type="bar"
          :data="chartData"
          :options="chartOptions"
          :height="350"
        />

        <div
          class="w-100 text-center justify-content-center align-self-center py-6"
          v-else
        >
          <h4><i class="fa-solid fa-chart-simple fa-2x"></i></h4>
          <h4 class="h-100">No History!</h4>
          <p>Keep your machines online or refer people to start earning.</p>
        </div>
      </div>
    </div>

    <!-- Your Machines -->
    <div class="row mt-4">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-md-10">
            <h5 class="mb-1">Your Machines</h5>
            <p class="text-sm">All your machines are listed here.</p>
          </div>

          <div class="col-md-2 d-flex align-items-center justify-content-end">
            <button
              class="btn btn-outline-primary btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#addMachine"
            >
              + Add Machine
            </button>
          </div>
        </div>
        <div class="row">
          <div class="table-responsive" v-if="machines.length > 0">
            <Machines :machines="machines"></Machines>
          </div>
          <div
            class="w-100 text-center justify-content-center align-self-center py-6"
            v-else
          >
            <h4><i class="fa-solid fa-desktop fa-2x"></i></h4>
            <h4 class="h-100">No Machines!</h4>
            <p>Click on + Add Machine to start renting compute.</p>
          </div>
        </div>
      </div>
    </div>

    <!-- referral list -->
    <div class="row mt-4">
      <div class="col-md-12 pt-4">
        <div class="row">
          <div class="col-md-10">
            <h5 class="mb-1">Your Referrals</h5>
            <p class="text-sm">
              Earn 2,500 Exa Compute Points (xCP) for every successful referral!
              Plus, enjoy a 0.5% commission on their earned xCP—forever!
            </p>
          </div>

          <div class="col-md-2 d-flex align-items-center justify-content-end">
            <button
              class="btn btn-outline-dark btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#viewReferralLinkModal"
            >
              <i class="fa fa-group"></i> &nbsp;Refer People
            </button>
          </div>
        </div>

        <div class="row">
          <div class="table-responsive" v-if="referrals.length > 0">
            <table class="table table-striped border">
              <thead class="bg-dark text-white">
                <th class="px-3 py-2 text-sm">Name</th>
                <th class="px-3 py-2 text-sm">Referral Time</th>
                <th class="px-3 py-2 text-sm">Machine Rented?</th>
                <th class="px-3 py-2 text-sm">Machine Activated?</th>
                <th class="px-3 py-2 text-sm">Referral Points Earned</th>
              </thead>
              <tbody>
                <tr v-for="referral in referrals" :key="referral._id">
                  <td class="px-3 text-sm">{{ referral.referral.name }}</td>
                  <td class="px-3 text-sm">
                    {{ referral.referral.createdAt }}
                  </td>
                  <td
                    class="px-3 text-sm"
                    v-tooltip.bottom="{
                      value:
                        'To earn 2500 xCP, ask them to connect the machine to ExaCompute.',
                      autoHide: false,
                    }"
                  >
                    {{ referral.machineRented ? "Yes" : "No" }}
                  </td>
                  <td class="px-3 text-sm">
                    {{
                      referral.redemptionDate
                        ? referral.redemptionDate
                        : "Not Active"
                    }}
                  </td>
                  <td class="px-3 text-sm">
                    {{ referral.machineRented ? 2500 : 0 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="w-100 text-center justify-content-center align-self-center py-6"
            v-else
          >
            <h4><i class="fa-solid fa-users-viewfinder fa-2x"></i></h4>
            <h4 class="h-100">No referrals!</h4>
            <p class="">People you have referred will appear here.</p>
          </div>
        </div>
      </div>
    </div>
    <Toast />
  </div>

  <!-- Add Machine Modal -->
  <div
    class="modal"
    id="addMachine"
    aria-labelledby="addMachineModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Add Machine</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12">
              <!-- <img
                :src="require('@/assets/img/add-machine.png')"
                alt="ExaDrive Logo"
                class="product-logo"
                
              /> -->
              <p>
                Enter name and description of your machine. <br />Machine token
                will be generated after you add your machine.
              </p>
              <hr class="horizontal dark my-4" />
              <form
                id="addCreditsForm"
                role="form"
                @submit.prevent="addMachine()"
              >
                <label for="machineName" class="form-label"
                  >Machine Name (3-63 chars)</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="machineName"
                    placeholder="Home Computer"
                    class="form-control"
                    id="machineName"
                    v-model="addMachineName"
                    aria-label="Machine Name"
                    autocomplete="off"
                  />
                </div>

                <label for="machineDescription" class="form-label"
                  >Machine Description (3-63 chars)</label
                >
                <div class="input-group mb-3">
                  <input
                    type="text"
                    name="machineDescription"
                    placeholder="Home computer with GPU RTX 307, 16GB RAM..."
                    class="form-control"
                    id="machineDescription"
                    v-model="addMachineDescription"
                    aria-label="Machine Description"
                    autocomplete="off"
                  />
                </div>

                <hr class="horizontal dark my-4" />

                <div class="my-4">
                  <p class="font-weight-bold">Please verify reCaptcha:</p>
                  <RecaptchaV2
                    @widget-id="handleWidgetId"
                    @error-callback="handleErrorCalback"
                    @expired-callback="handleExpiredCallback"
                    @load-callback="handleLoadCallback"
                  />
                </div>
                <button
                  type="submit"
                  class="btn btn-primary mt-4"
                  id="addMachineButton"
                  :disabled="disableAddMachineBtn"
                >
                  + Add Machine
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View Referral Link -->
  <div
    class="modal"
    id="viewReferralLinkModal"
    aria-labelledby="viewReferralLinLabel"
    aria-hidden="true"
    style="background-color: rgba(0, 0, 255, 0.2)"
  >
    <div class="modal-dialog modal-dialog-centered modal-md">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Your Referral Link</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="modal-body p-5 text-center">
          <div class="row">
            <div class="col-lg-12">
              <img
                :src="require('@/assets/img/gift.png')"
                alt=""
                class="img-fluid d-block mx-auto"
                style="width: 100px"
              />
            </div>
            <hr class="horizontal dark my-4" />
            <div class="col-lg-12">
              <template v-if="referralLink">
                <code style="font-size: 12px">{{ referralLink }}</code>
                <button
                  class="btn btn-outline-primary btn-sm d-block mx-auto mt-4"
                  @click="copyText(referralLink)"
                >
                  <i class="fa fa-copy"></i> Copy link
                </button>
                <hr class="horizontal dark my-4" />

                <p class="mt-4">
                  You and your referral will earn
                  <b>2500 Exa Compute Points (xCP)</b> after your referral
                  successfuly rents their machine! 🎉 <br /><br />
                  Additionally, you also get
                  <b>0.5% of their earned xCP</b>
                  perpetually!
                </p>
              </template>
              <p v-else class="text-center text-danger">
                <i class="fa fa-warning"></i> Sorry, referrals have been
                disabled for your account.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Toast from "primevue/toast";
import DefaultCounterCard from "../../examples/Cards/DefaultCounterCard.vue";
import Chart from "primevue/chart";
import Skeleton from "primevue/skeleton";
import { RecaptchaV2, useRecaptcha } from "vue3-recaptcha-v2";
const { handleReset } = useRecaptcha();
import Machines from "./components/Machines.vue";

export default {
  name: "RentMachineDashboard",
  components: {
    Toast,
    Skeleton,
    DefaultCounterCard,
    Chart,
    RecaptchaV2,
    Machines,
  },
  async mounted() {
    this.chartOptions = this.setChartOptions();
    this.getMachines();
    this.getReferrals();
    this.getReferralCode();
    this.getEarnings();
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      addMachineName: "",
      addMachineDescription: "",
      captchaResponseMessage: "",
      captchaResponse: "",
      widgetID: "",
      machines: [],
      referrals: [],
      disableAddMachineBtn: false,
      referralLink: null,
      earnings: {
        computeEarnings: 0,
        referralEarnings: 0,
        todaysEarnings: 0,
      },
      deviceOnline: false,
    };
  },
  methods: {
    handleWidgetId(widgetId) {
      // console.log("Widget ID: ", widgetId);
      this.widgetID = widgetId;
    },
    handleErrorCalback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleExpiredCallback() {
      this.captchaResponseMessage = "";
      this.captchaResponse = false;
    },
    handleLoadCallback(response) {
      // console.log("Load callback", response);
      this.captchaResponseMessage = response;
      this.captchaResponse = true;
    },
    addMachine() {
      this.disableAddMachineBtn = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] + "/org/rentCompute/addMachine",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          machineName: this.addMachineName,
          machineDescription: this.addMachineDescription,
          gRecaptchaResponse: this.captchaResponseMessage,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Machine updated! Reloading page...",
            life: 2000,
          });

          this.$confetti.start();
          setTimeout(() => {
            this.$confetti.stop();
          }, 1000);

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          this.disableAddMachineBtn = false;
          handleReset(this.widgetID);
          // console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while adding a new machine. \n" +
              err.response.data.errors[0] +
              (err.response.data.errors[1]
                ? "\n" + err.response.data.errors[1]
                : ""),
            life: 5000,
          });
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    getMachines() {
      axios
        .get(
          this.$store.getters["getServerURL"] +
            "/org/rentCompute/getAllMachines",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.machines = response.data;
          this.machines.reverse();
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getReferrals() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/referral/getAllReferrals",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.referrals = response.data;
          this.referrals.reverse();
        })
        .catch((err) => {
          // console.log(err);
        });
    },

    getReferralCode() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/referral/getReferralCode",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.referralLink = response.data.referralLink;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    getEarnings() {
      axios
        .get(
          this.$store.getters["getServerURL"] + "/org/rentCompute/getEarnings",
          {
            headers: { Authorization: localStorage.getItem("jwt") },
          }
        )
        .then((response) => {
          // console.log(response);
          this.earnings.computeEarnings = response.data.computeEarnings;
          this.earnings.referralEarnings = response.data.referralEarnings;
          this.earnings.todaysEarnings = response.data.chartData.todaysEarnings;
          this.chartData = response.data.chartData;
          this.deviceOnline = response.data.chartData.deviceOnline;
          this.setChartOptions();

          // console.log(this.chartData);
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "Copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },

    setChartOptions() {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue("--p-text-color");
      const textColorSecondary = documentStyle.getPropertyValue(
        "--p-text-muted-color"
      );
      const surfaceBorder = documentStyle.getPropertyValue(
        "--p-content-border-color"
      );

      return {
        maintainAspectRatio: false,
        aspectRatio: 0.8,
        plugins: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          legend: {
            labels: {
              color: textColor,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            stacked: true,
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
      };
    },
  },
};
</script>

<style>
.no-shadow {
  box-shadow: none;
}
</style>
