<template>
  <div class="container">
    <div class="row pt-4 mt-4">
      <div class="col-12">
        <h3>👋 Hi, {{ userName }}!</h3>
        <p class="pb-0 mb-0">Here's an overview of everything that matters!</p>
      </div>
    </div>
    <div class="row h-100">
      <div class="col-lg-5">
        <div class="row" data-aos="fade-up">
          <div class="col-lg-12 col-sm-12 mt-4">
            <div class="card">
              <div class="card-header pb-2 border-bottom">
                <h6 class="font-weight-bold">
                  <span class="fa fa-star"></span> Quick Links
                </h6>
              </div>
              <div class="card-body py-2">
                <div class="row">
                  <div class="col-6">
                    <ul class="quick-links">
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp; <a href="/apps">View your Apps</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/apps/create">Create App</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/buckets">View your Buckets</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span> &nbsp;
                        <a href="/account/settings">Settings</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6">
                    <ul class="quick-links">
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a href="/exacompute-rent">Rent your machine</a>
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a
                          href="https://www.npmjs.com/package/exadrive-sdk"
                          target="_blank"
                          >Get the SDK</a
                        >
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a href="https://exa-drive.gitbook.io/" target="_blank"
                          >Docs</a
                        >
                      </li>
                      <li>
                        <span class="fa fa-arrow-right"></span>
                        &nbsp;
                        <a
                          href="https://www.youtube.com/playlist?list=PLMuDctE3L7yGnuJUCInBrfq8tHTC7avz2"
                          target="_blank"
                          >Quick Start Guides</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-sm-12 mt-4">
            <div class="card">
              <div
                class="card-header pb-2 border-bottom d-flex justify-content-between"
              >
                <h6 class="font-weight-bold">
                  <span class="fa fa-chart-simple"></span> Usage by Numbers
                </h6>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-6 col-sm-12 mt-4">
                    <default-counter-card
                      :count="formatBytes(dataEgress).num || 0"
                      :suffix="formatBytes(dataEgress).suffix || ''"
                      color="dark"
                      shadow="no-shadow"
                      title="Egress this month"
                      description="Updated every 5 mins."
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 mt-4">
                    <default-counter-card
                      :count="formatBytes(dataIngress).num || 0"
                      :suffix="formatBytes(dataIngress).suffix || ''"
                      color="dark"
                      shadow="no-shadow"
                      title="Ingress this month"
                      description="Updated every 5 mins."
                    />
                  </div>

                  <div class="col-lg-6 col-sm-12 mt-4">
                    <default-counter-card
                      :count="response.fileCount || 0"
                      suffix=""
                      color="dark"
                      shadow="no-shadow"
                      title="Total Files"
                      description="Updated at every upload."
                    />
                  </div>
                  <div class="col-lg-6 col-sm-12 mt-4">
                    <default-counter-card
                      :count="response.appCount || 0"
                      suffix=""
                      color="dark"
                      shadow="no-shadow"
                      title="Total Apps"
                      description="Max. 25 apps allowed."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-12 col-sm-12 mt-4">
            <div class="card">
              <div
                class="card-header pb-2 border-bottom d-flex justify-content-between"
              >
                <h6 class="font-weight-bold">
                  <span class="fa fa-microchip"></span> Your connected machines
                </h6>
                <a href="/exacompute-rent" class="btn btn-primary btn-sm mb-2"
                  >View</a
                >
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-6">
                    <default-counter-card
                      :count="response.machineCount || 0"
                      suffix=""
                      shadow="no-shadow"
                      color="dark"
                      title="Total Machines"
                      description="No limit."
                    />
                  </div>
                  <div class="col-6">
                    <default-counter-card
                      :count="response.earnings || 0"
                      suffix=""
                      shadow="no-shadow"
                      color="dark"
                      title="Exa Compute Points"
                      description="Total accumulated."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 pt-4">
        <div class="row">
          <div class="col-12">
            <div class="card mb-4" data-aos="fade-up">
              <div class="card-header pb-2 border-bottom">
                <h6 class="font-weight-bold">
                  <i class="fa fa-microchip"></i> ExaCompute Usage (across all
                  Apps)
                </h6>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-12 text-center my-5">
                    <font-awesome-icon
                      icon="fa-solid fa-hashtag"
                      class="fa-2x"
                    />
                    <h4>Coming Soon!</h4>
                    <p>
                      ExaCompute will be avaialable soon! <br />We are currently
                      onboarding compute nodes.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card h-100" data-aos="fade-up">
              <div class="card-header pb-2 border-bottom">
                <h6 class="font-weight-bold">
                  <span class="fa fa-upload"></span> ExaDrive Usage - last 10
                  uploaded files (across all Apps)
                </h6>
              </div>
              <div class="card-body d-flex p-0 m-0">
                <div class="table-responsive w-100" v-if="files.length > 0">
                  <table class="table table-striped">
                    <thead class="table-light bg-light">
                      <th>Filename</th>
                      <th>Directory</th>
                      <th>Uploaded On</th>
                      <th>Size</th>
                      <th>CDN URL</th>
                    </thead>
                    <tbody>
                      <tr v-for="file in files" :key="file._id">
                        <td
                          :title="file.originalName"
                          class="px-4"
                          v-tooltip.bottom="{
                            value: file.originalName,
                            autoHide: false,
                          }"
                        >
                          <span
                            :class="mimeTypeToIcon(file.fileDetails.mimetype)"
                          ></span>
                          &nbsp;{{ file.originalName }}
                        </td>
                        <td
                          :title="file.virtualPath"
                          class="px-4"
                          v-tooltip.bottom="{
                            value: file.virtualPath,
                            autoHide: false,
                          }"
                        >
                          <code class="text-dark">{{ file.virtualPath }}</code>
                        </td>
                        <td
                          class="px-4 text-sm"
                          v-tooltip.bottom="{
                            value: file.uploadedOn,
                            autoHide: false,
                          }"
                        >
                          {{ file.uploadedOn }}
                        </td>
                        <td class="px-4 text-sm">
                          {{
                            formatBytes(file.fileDetails.size).num +
                            " " +
                            formatBytes(file.fileDetails.size).suffix
                          }}
                        </td>
                        <td class="px-4">
                          <button
                            class="btn btn-link btn-sm mb-0 p-2"
                            @click="copyText(file.publicLink)"
                          >
                            <span class="fa fa-copy"></span>
                            Copy URL
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div
                  class="w-100 text-center justify-content-center align-self-center py-6"
                  v-else
                >
                  <h4><span class="fa fa-info-circle fa-2x"></span></h4>
                  <h4 class="h-100">No recent files.</h4>
                  <p>You can upload files through SDK or Buckets.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Toast />
  </div>
</template>
<script>
import DefaultCounterCard from "@/examples/Cards/DefaultCounterCard.vue";
import DefaultProjectCard from "../../components/DefaultProjectCard.vue";
import Tag from "primevue/tag";
import axios from "axios";
import Panel from "primevue/panel";
import Toast from "primevue/toast";
export default {
  name: "DashboardDefault",
  components: {
    DefaultCounterCard,
    DefaultProjectCard,
    Tag,
    Panel,
    Toast,
  },
  mounted() {
    axios
      .get(this.$store.getters["getServerURL"] + "/auth/getUser", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((resp) => {
        this.getSummary();
      })
      .catch((err) => {
        if (err.response.status == 401);
        this.logout();
      });
  },
  data() {
    return {
      response: {},
      dataIngress: 0,
      dataEgress: 0,
      files: [],
      userName: localStorage.getItem("userName"),
    };
  },
  methods: {
    getSummary() {
      axios
        .get(this.$store.getters["getServerURL"] + "/dashboard/getSummary", {
          headers: { Authorization: localStorage.getItem("jwt") },
        })
        .then((response) => {
          // console.log(response);
          this.response = response.data;
          this.files = response.data.latestFiles;
          this.dataIngress = response.data.latestInvoice.dataIngress
            ? response.data.latestInvoice.dataIngress
            : 0;
          this.dataEgress = response.data.latestInvoice.dataEgress
            ? response.data.latestInvoice.dataEgress
            : 0;
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return {
        num: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)),
        suffix: sizes[i],
      };
    },
    copyText(text) {
      var context = this;
      navigator.clipboard.writeText(text).then(
        function () {
          context.$toast.add({
            severity: "success",
            summary: "Copied",
            detail: "CDN URL copied to clipboard:\n" + text,
            life: 5000,
          });
        },
        function (err) {
          context.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to copy CDN URL:\n" + text,
          });
        }
      );
    },
    mimeTypeToIcon(mimeType) {
      switch (mimeType) {
        case "application/pdf":
          return "fa fa-file-pdf";
        case "image/jpeg":
          return "fa fa-file-image ";
        case "image/jpg":
          return "fa fa-file-image ";
        case "image/svg+xml":
          return "fa fa-shapes ";
        case "image/png":
          return "fa fa-file-image ";
        case "image/gif":
          return "fa fa-file-image ";
        case "text/html":
          return "fa fa-html5";
        case "text/css":
          return "fa fa-css3";
        case "application/x-javascript":
          return "fa fa-code";
        default:
          return "fa fa-file";
      }
    },
    logout() {
      localStorage.removeItem("jwt");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("expiresIn");
      localStorage.removeItem("userID");
      localStorage.removeItem("userName");
      this.$router.push("/authentication/signin");
    },
  },
};
</script>

<style scoped>
td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.quick-links {
  list-style: none;
  padding: 0;
}

.quick-links li {
  padding: 10px;
}

.quick-links li a:hover {
  color: initial;
}
.quick-links li:hover {
  background: #eee;
}

.no-shadow {
  box-shadow: none;
}
</style>
