<template>
  <div class="mb-3">
    <label for="ram" class="form-label">Amount of RAM (GB)</label>
    <input
      v-model.number="ram"
      type="number"
      class="form-control"
      id="ram"
      placeholder="Enter RAM in GB"
      min="8"
    />
  </div>

  <div class="mb-3">
    <label class="form-label">GPU Tier</label>
    <select v-model="gpuTier" class="form-select">
      <option value="0">No GPU</option>
      <option value="2">Low-end (RTX 3060 or equivalent)</option>
      <option value="4">Mid-range (RTX 3080 or equivalent)</option>
      <option value="8">High-end (RTX 4090 or equivalent)</option>
    </select>
  </div>

  <div class="mb-3" v-if="gpuTier != '0'">
    <label for="numGpu" class="form-label"
      >Number of Nvidia CUDA compatible GPUs</label
    >
    <input
      v-model.number="numGpu"
      type="number"
      class="form-control"
      id="numGpu"
      placeholder="Enter number of GPUs"
      min="1"
      max="1024"
    />
  </div>

  <div class="mb-3">
    <label class="form-label"
      >Tasks executed per day: {{ numTasks }} (One Task = One small prompt for
      Llama-3.1-8B)</label
    >
    <input
      v-model.number="numTasks"
      type="range"
      class="form-range"
      min="0"
      max="100"
    />
  </div>

  <div class="mb-3">
    <label class="form-label">Days online: {{ daysOnline }} days</label>
    <input
      v-model.number="daysOnline"
      type="range"
      class="form-range"
      min="1"
      max="365"
    />
  </div>

  <div class="mt-4">
    <button
      @click="calculateEarnings"
      class="btn btn-md btn-primary w-100"
      id="calculateEarningsBtn"
    >
      Calculate Earnings
    </button>
  </div>

  <div
    v-if="earnings !== null"
    class="alert mt-4 text-center"
    style="background-color: rgba(255, 215, 0, 0.7)"
  >
    <h5 class="">Estimated Earnings:</h5>
    <h3>
      <strong>~{{ earnings.toLocaleString() }}</strong> xCP
    </h3>
    <p>
      Exa Compute Points (xCP) will be redeemable for XAP (Exa Protocol Token),
      USDC, or USDT at the time of token generation event (TGE).
    </p>
  </div>
  <div v-if="calculationError">
    <p class="text-danger text-center">{{ calculationError }}</p>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "EarningsCalculator",
  data() {
    return {
      ram: 8,
      gpuTier: 0,
      numGpu: 1,
      daysOnline: 1,
      numTasks: 0,
      earnings: null,
      calculationError: null,
    };
  },
  methods: {
    calculateEarnings() {
      this.earnings = null;
      this.calculationError = null;
      if (this.numGpu > 1024) this.numGpu = 1024;
      if (this.numGpu < 1) this.numGpu = 1;
      if (this.daysOnline > 365) this.daysOnline = 365;
      if (this.daysOnline < 1) this.daysOnline = 1;
      if (this.ram > 4096) this.ram = 4096;
      if (this.ram < 8) this.ram = 8;

      const calculateBtn = document.getElementById("calculateEarningsBtn");
      calculateBtn.innerHTML = `<div class="spinner-grow spinner-grow-sm" role="status">
  <span class="visually-hidden">Loading...</span>
</div>`;
      calculateBtn.disabled = true;
      axios
        .post(
          this.$store.getters["getServerURL"] +
            "/org/rentCompute/earningCalculator",
          {
            ram: this.ram,
            gpuTier: this.gpuTier,
            numGpu: this.numGpu,
            daysOnline: this.daysOnline,
            numTasks: this.numTasks,
          }
        )
        .then((response) => {
          calculateBtn.innerHTML = `Calculate Earnings`;
          calculateBtn.disabled = false;
          // console.log(response.data);
          this.earnings = response.data.earnings;
        })
        .catch((err) => {
          calculateBtn.innerHTML = `Calculate Earnings`;
          calculateBtn.disabled = false;
          // console.log(err);
          this.calculationError = err.response.data.error;
        });
      //  console.log(this.daysOnline);
      //  console.log(parseInt(this.gpuTier));
      //  console.log(this.numGpu);
      //  console.log(this.ram);
      //  this.earnings =
      //    this.daysOnline *
      //    (baseEarningsPerDay +
      //      (parseInt(this.gpuTier) * this.numGpu * 160 + this.ram * 20) *
      //        this.numTasks);
    },
  },
};
</script>

<style scoped>
input[type="range"] {
  width: 100%;
}
</style>
