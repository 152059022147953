<template>
  <div
    class="card fade-it highlight-card h-100"
    data-aos="fade-up"
    data-aos-once="true"
  >
    <div class="p-3 card-body">
      <div class="d-flex">
        <div class="p-2 avatar avatar-xl bg-gradient-dark border-radius-md">
          <Avatar :size="80" variant="pixel" :name="title" />
        </div>
        <div class="my-auto ms-3">
          <h6 class="my-0 pb-1">{{ title }}</h6>

          <div class="ribbon-sm bg-primary" v-if="active == 1 && !appLoading">
            Active
          </div>
          <div class="ribbon-sm bg-danger" v-if="active == 2 && !appLoading">
            Suspended
          </div>

          <p class="text-xs text-muted my-0">
            <span class="font-weight-bold">CDN:</span>

            {{ appIdentifier }}.exadrivecdn.com
          </p>
          <p
            class="badge badge-dark badge-sm mb-0"
            v-if="domainName && domainOwnershipVerified && domainSSLGenerated"
          >
            <i class="fa fa-globe text-white"></i> &nbsp;
            <a :href="'https://' + domainName" target="_blank">{{
              domainName
            }}</a>
          </p>
        </div>
        <div class="ms-auto mt-3">
          <div class="dropdown">
            <button
              id="navbarDropdownMenuLink"
              class="btn btn-link text-secondary ps-0 pe-2"
              :class="{ show: showMenu }"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
              <i class="text-lg fa fa-cog"></i>
            </button>
            <div
              class="dropdown-menu dropdown-menu-end me-sm-n4 me-n3"
              :class="{ show: showMenu }"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <a
                class="dropdown-item border-radius-md"
                data-bs-toggle="modal"
                :data-bs-target="'#apiKeyModal' + appID"
                >View API Key
              </a>
              <a
                class="dropdown-item border-radius-md"
                data-bs-toggle="modal"
                :data-bs-target="'#updateAppModal' + appID"
                >Update App</a
              >
              <a
                class="dropdown-item border-radius-md"
                href="#"
                @click="drawerVisible = true"
                >Settings</a
              >
            </div>
          </div>
        </div>
      </div>
      <hr class="horizontal dark" />
      <p class="mt-3 text-sm">
        <span class="font-weight-bolder">Description:</span>
        {{ description }}
      </p>
      <hr class="horizontal dark" />
      <div class="row">
        <div class="col-12 d-flex justify-content-end">
          <button
            class="btn btn-link btn-sm px-3 mb-0"
            @click="this.$router.push('/buckets/filemanager/' + appID)"
          >
            <i class="fa fa-bucket"></i> View Bucket
          </button>
          &nbsp;<button
            class="btn btn-link btn-sm px-3 mb-0"
            @click="this.$router.push('/compute/collection/' + appID)"
          >
            <i class="fa fa-server"></i> View Compute
          </button>
        </div>
      </div>
      <Toast />
    </div>
  </div>

  <!-- Drawer -->
  <Drawer v-model:visible="drawerVisible" :header="title" position="right">
    <div class="row py-2 border-bottom border-top">
      <div class="col">
        <h6><i class="fa fa-key"></i> Generate new API Key</h6>
        <p class="text text-sm">
          Once a new API Key is generated the previous API Key will stop working
          immediately. This action affects the functioning of SDK.
          <br /><br />You will have to setup the SDK with the new API Key.
        </p>

        <button
          type="button"
          class="btn btn-primary btn-sm mt-2"
          @click="confirm1($event)"
          id="generateAPIKeyButton"
        >
          Generate new API Key
        </button>
        <ConfirmPopup />
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6>
          <i class="fa fa-globe"></i>
          {{ domainName ? "Manage Domain" : "Connect a domain" }}
        </h6>
        <p class="text-sm">
          You can connect a custom domain and serve a static website or your
          files through that.
        </p>
        <a
          class="btn btn-primary btn-sm mt-2"
          data-bs-toggle="modal"
          :data-bs-target="'#connectDomainModal' + appID"
          >{{ domainName ? "Manage Domain" : "Connect a domain" }}
        </a>
      </div>
    </div>
    <div class="row py-2 border-bottom">
      <div class="col">
        <h6><i class="fa fa-trash"></i> Permanently delete app</h6>
        <p class="text-sm">
          Delete the app and all associated files. This action cannot be undone.
        </p>
        <a
          class="btn btn-danger btn-sm mt-2"
          data-bs-toggle="modal"
          :data-bs-target="'#deleteAppModal' + appID"
          >Delete App
        </a>
      </div>
    </div>
  </Drawer>

  <!-- Update App name and description Modal -->
  <div
    class="modal"
    :id="'updateAppModal' + appID"
    aria-labelledby="updateAppModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <p class="text-dark text-xs text-center mb-0 pb-0">
            App Identifier: {{ appIdentifier }}
          </p>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body py-4">
          <div class="mb-2 pr-5">
            <label for="appname" class="form-label">New App Name</label>
            <input
              id="appnem"
              type="text"
              class="form-control"
              placeholder=""
              v-model="appName"
            />
          </div>
          <div class="mb-2 pr-5">
            <label for="appdesc" class="form-label">New App Description</label>
            <input
              id="appdesc"
              type="text"
              class="form-control"
              placeholder=""
              v-model="appDesc"
            />
          </div>
          <p class="text-xs mt-3">
            <span class="fa fa-info-circle"></span>&nbsp; You cannot change the
            App Identifier.
          </p>
          <div class="mt-4 pr-5">
            <button class="btn btn-primary btn-sm" @click="updateApp()">
              Update App
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- View APIKey Modal -->
  <div
    class="modal"
    :id="'apiKeyModal' + appID"
    aria-labelledby="apiKeyModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span class="fa fa-close"></span>
          </button>
        </div>
        <div class="modal-body text-center py-5">
          <p class="text-sm text-danger">
            <font-awesome-icon icon="triangle-exclamation" /> Keep your API key
            safe. <br />
            Sharing this API Key will grant access to your App and all of its
            data.
          </p>
          <hr />
          <Inplace :active="showAPIKey">
            <template #display>Click to view API Key</template>
            <template #content>
              <code class="text-dark font-weight-bold"
                >App ID: {{ appID }}
              </code>
              <br />
              <code class="text-dark font-weight-bold"
                >API Key: {{ apiKey }}</code
              >

              <p class="text-center font-weight-bold text-xs mt-4">
                Generated on: <br />{{ getDate(parseInt(apiKeyGenTime)) }}
              </p>
            </template>
          </Inplace>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete App Modal -->
  <div
    class="modal"
    :id="'deleteAppModal' + appID"
    aria-labelledby="deleteAppModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">Delete App?</h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body py-5 px-5">
          <h5 class="font-weight-bold text-danger text-center">
            <font-awesome-icon icon="triangle-exclamation" /> Warning!
          </h5>
          <p class="text-center">
            You are about to permanently delete
            <span class="text-danger">{{ title }}!</span> <br />Once deletion is
            initiated you will not be able to undo it. <br />
            Files associated with this App will be permanently deleted and
            cannot be recovered.
            <br />
          </p>
          <p class="font-weight-bold mt-4 pt-4">
            Type the App name ({{ title }}) below to confrim:
          </p>
          <input
            class="form-control"
            type="text"
            name=""
            v-model="confirmText"
            placeholder="Enter app name..."
          />
          <p class="text-danger text-sm" v-if="confirmError">
            You must enter the "App name" above to confirm deletion.
          </p>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
          <button type="button" class="btn btn-danger" @click="deleteApp()">
            Delete App
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Connect Domain Modal -->
  <div
    class="modal"
    :id="'connectDomainModal' + appID"
    aria-labelledby="connectDomainModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="">
            <i class="fa fa-link"></i> Connect a domain | {{ title }}
          </h5>
          <button
            type="button"
            class="btn-close text-dark"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-close"></i>
          </button>
        </div>
        <div class="modal-body p-0">
          <div
            class="text-center py-3 pt-5"
            v-if="
              (domainName && domainOwnershipVerified && domainSSLGenerated) ||
              sslDeployed
            "
          >
            <img
              :src="require('@/assets/img/domain_connected.svg')"
              alt=""
              class="img-fluid w-20 text-center"
            />
            <h4 class="text-center pt-4">
              <i class="fa fa-check-circle text-success"></i> Domain is
              connected!
            </h4>
            <p class="text-xs" v-if="domainSSLGenerationTime">
              Connected on: {{ getDate(parseFloat(domainSSLGenerationTime)) }}
            </p>

            <hr class="horizontal dark" />
            <h3>
              <a :href="'https://' + addDomainName" target="_blank">{{
                addDomainName
              }}</a>
            </h3>
            <hr class="horizontal dark" />
            <buttom
              class="btn btn-outline-danger mt-4 p-2"
              @click="confirmDisconnect()"
              >Disconnect Domain</buttom
            >
          </div>

          <div class="stepper d-flex flex-column px-5 pt-5 pb-4" v-else>
            <div class="d-flex">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div
                  class="rounded-circle py-2 px-3 bg-white text-success mb-1 font-weight-bolder"
                  v-if="domainName || domainAdded"
                >
                  <i class="fa fa-check fa-2x"></i>
                </div>
                <div
                  class="rounded-circle py-2 px-3 bg-white text-primary mb-1 font-weight-bolder"
                  v-else
                >
                  <i class="fa fa-globe fa-2x"></i>
                </div>
                <div class="line h-100"></div>
              </div>
              <div class="mx-4 pb-4 w-80">
                <p class="text-dark text-lg font-weight-bolder mb-0">
                  Step 1: Add a domain
                </p>

                <ul class="mb-2 pb-2 text-sm">
                  <li>
                    Domain name <b>must not</b> begin with http, https or any
                    other protocol.
                  </li>
                  <li>Subdomains are allowed.</li>
                </ul>

                <div class="input-group">
                  <input
                    type="text"
                    name="domain"
                    placeholder="Enter a valid domain name..."
                    class="form-control mb-3"
                    id="domainNameInput"
                    :disabled="domainName || domainAdded"
                    v-model="addDomainName"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-primary"
                      type="button"
                      :disabled="domainName || domainAdded"
                      @click="confirmAddDomain()"
                    >
                      Add Domain
                    </button>
                  </div>
                </div>

                <div v-if="domainName || domainAdded">
                  <button
                    class="btn btn-outline-danger btn-sm py-1 mb-0 px-4"
                    @click="removeDomain()"
                  >
                    Remove Domain
                  </button>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div
                  class="rounded-circle py-2 px-3 bg-white text-primary font-weight-bolder mb-1"
                  v-if="!(domainOwnershipVerified || domainRecordVerified)"
                >
                  <i class="fa fa-user-check fa-2x"></i>
                </div>
                <div
                  class="rounded-circle py-2 px-3 bg-white text-success font-weight-bolder mb-1"
                  v-else
                >
                  <i class="fa fa-check fa-2x"></i>
                </div>

                <div class="line h-100"></div>
              </div>
              <div class="mx-4 pb-4 w-80">
                <p class="text-dark text-lg font-weight-bolder mb-0">
                  Step 2: Verify domain ownership
                </p>
                <p class="text-muted pb-2 text-sm">
                  Once a domain name is added, add the following DNS records to
                  it.<br />
                  It can take upto 30 mins to verify the records.
                </p>
                <p
                  v-if="!(domainName || domainAdded)"
                  class="alert alert-light"
                >
                  <i class="fa fa-spinner fa-spin"></i> Waiting for domain name
                  to be added.
                </p>

                <table class="table border" v-else>
                  <thead>
                    <tr class="bg-light">
                      <th class="p-1 px-2">Record type</th>
                      <th class="p-1 px-2">Host</th>
                      <th class="p-1 px-2">Value</th>
                      <th class="p-1 px-2">TTL</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="getSubdomain(addDomainName)">
                      <td class="p-1 px-2">A</td>
                      <td class="p-1 px-2">
                        {{ getSubdomain(addDomainName) }}
                      </td>
                      <td class="p-1 px-2">3.6.37.80</td>
                      <td class="p-1 px-2">Auto</td>
                    </tr>
                    <tr v-else>
                      <td class="p-1 px-2">A</td>
                      <td class="p-1 px-2">@</td>
                      <td class="p-1 px-2">3.6.37.80</td>
                      <td class="p-1 px-2">Auto</td>
                    </tr>
                    <tr v-if="!getSubdomain(addDomainName)">
                      <td class="p-1 px-2">A</td>
                      <td class="p-1 px-2">www</td>
                      <td class="p-1 px-2">3.6.37.80</td>
                      <td class="p-1 px-2">Auto</td>
                    </tr>
                  </tbody>
                </table>
                <button
                  class="btn btn-primary btn-sm mt-2 mb-1"
                  :id="'verifyDomainOwnershipBtn' + appIdentifier"
                  type="button"
                  v-if="domainAdded || domainName"
                  :disabled="
                    domainOwnershipVerified ||
                    domainRecordVerified ||
                    storedTimestamp > currentTime
                  "
                  @click="verifyRecords()"
                >
                  Verify Ownership
                  {{
                    storedTimestamp - currentTime > 0
                      ? "(" +
                        ((storedTimestamp - currentTime) / 1000).toFixed(0) +
                        " seconds)"
                      : ""
                  }}
                </button>
              </div>
            </div>
            <div class="d-flex">
              <div class="d-flex flex-column pr-4 align-items-center">
                <div
                  class="rounded-circle py-2 px-3 bg-white text-primary mb-1"
                >
                  <i class="fa fa-lock fa-2x"></i>
                </div>
                <div class="line h-100 d-none"></div>
              </div>
              <div class="mx-4 w-80 mb-4">
                <p class="text-dark text-lg font-weight-bolder mb-0">
                  Step 3: Issue SSL
                </p>
                <p class="text-muted pb-2 text-sm">
                  SSL will be issued automatically once the domain is verified
                  by the Certificate Authority (CA). Please check the status
                  later.
                </p>
                <p
                  v-if="!(domainOwnershipVerified || domainRecordVerified)"
                  class="alert alert-light"
                >
                  <i class="fa fa-spinner fa-spin"></i> Waiting for domain
                  ownership to be verified.
                </p>
                <p
                  v-if="domainOwnershipVerified || domainRecordVerified"
                  class="alert alert-light"
                >
                  <i class="fa fa-spinner fa-spin"></i> Issuing SSL certificates
                  now...
                </p>

                <p
                  class="text-xs"
                  v-if="domainOwnershipVerified || domainRecordVerified"
                >
                  <i class="fa fa-circle-exclamation"></i> If the SSL issuance
                  process takes longer than 1 hour, it may have failed because
                  the Certificate Authority (CA) was unable to verify the
                  domain. In some cases, the CA may take up to 24 hours to
                  complete verification. To resolve this, you can remove the
                  domain and reinitiate the process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Toast />
</template>

<script>
import ConfirmPopup from "primevue/confirmpopup";
import axios from "axios";
import Inplace from "primevue/inplace";
import Avatar from "vue-boring-avatars";
import Drawer from "primevue/drawer";
import Toast from "primevue/toast";
export default {
  name: "AppCard",
  components: {
    Inplace,
    Avatar,
    Drawer,
    Toast,
    ConfirmPopup,
  },
  props: {
    appIdentifier: {
      type: String,
      default: "",
    },
    active: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: "",
    },
    appID: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    lastUpdated: {
      type: String,
      default: "",
    },
    apiKey: {
      type: String,
      default: null,
    },
    apiKeyGenTime: {
      type: String,
      default: null,
    },
    domainName: {
      type: String,
      default: null,
    },
    domainOwnershipVerified: {
      type: Boolean,
      default: false,
    },
    domainOwnershipVerificationTime: {
      type: Number,
      default: 9999999999999,
    },
    domainSSLGenerated: {
      type: Boolean,
      default: false,
    },
    domainSSLGenerationTime: {
      type: String,
      default: null,
    },
  },
  mounted() {
    // Load the timestamp from localStorage

    this.storedTimestamp =
      parseFloat(
        localStorage.getItem("lastDomainVerificationTime" + this.addDomainName)
      ) || 0;

    // Update `currentTime` every second
    setInterval(() => {
      this.currentTime = Date.now();
      this.storedTimestamp =
        parseFloat(
          localStorage.getItem(
            "lastDomainVerificationTime" + this.addDomainName
          )
        ) || 0;
    }, 1000);
  },
  data() {
    return {
      appDesc: this.description,
      appName: this.title,
      showMenu: false,
      confirmText: "",
      confirmError: false,
      drawerVisible: false,
      showAPIKey: false,
      addDomainName: this.domainName,
      domainAdded: false, //for axios call
      domainRecordVerified: false, //for axios call
      sslDeployed: false,
      storedTimestamp: null, // Timestamp from localStorage
      currentTime: Date.now(), // Current time
    };
  },
  methods: {
    getDate(UNIX_timestamp) {
      var a = new Date(UNIX_timestamp);
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var year = a.getFullYear();
      var month = months[a.getMonth()];
      var date = a.getDate();
      var finetime = a.getHours() + ":" + a.getMinutes() + ":" + a.getSeconds();

      var time = date + " " + month + " " + year + " " + finetime;
      return time;
    },
    deleteApp() {
      this.confirmError = false;
      if (this.title != this.confirmText) {
        this.confirmError = true;
        return;
      }
      axios({
        method: "delete",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/deleteApp/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          // console.log(response);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while deleting the app. \n" + err.response.data.message,
            life: 5000,
          });
        });
    },
    confirm1(event) {
      this.$confirm.require({
        target: event.currentTarget,
        message: "Are you sure you want to proceed?",
        icon: "fa fa-exclamation-triangle",
        rejectProps: {
          label: "Cancel",
          severity: "secondary",
          outlined: true,
        },
        acceptProps: {
          label: "Yes",
          severity: "secondary",
        },
        accept: () => {
          this.generateAPIKey();
        },
        reject: () => {},
      });
    },
    confirmAddDomain() {
      //validate domain
      document.getElementById("domainNameInput").classList.remove("is-valid");
      document.getElementById("domainNameInput").classList.remove("is-invalid");

      // console.log(this.addDomainName);
      if (!this.validateDomain(this.addDomainName)) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "Invalid domain name.",
          life: 3000,
        });

        document.getElementById("domainNameInput").classList.add("is-invalid");
      }

      document.getElementById("domainNameInput").classList.add("is-valid");

      //else continue with a prompt to add the domain
      if (
        !confirm(
          "Are you sure you want to add this domain:\n\n " +
            this.addDomainName +
            "?"
        )
      ) {
        return;
      } else {
        this.addDomain(this.addDomainName);
      }
    },
    generateAPIKey() {
      const generateAPIKeyButton = document.getElementById(
        "generateAPIKeyButton"
      );
      generateAPIKeyButton.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Loading...`;
      generateAPIKeyButton.disabled = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/generateAPIKey/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
      })
        .then((response) => {
          // console.log(response.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "API Key generated. Reloading page...",
            life: 2000,
          });
          generateAPIKeyButton.innerHTML = `Generate API Key`;

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
            generateAPIKeyButton.disabled = false;
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          generateAPIKeyButton.innerHTML = `Generate New API Key`;
          generateAPIKeyButton.disabled = false;
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Unable to generate the API Key. Try again later.",
            life: 2000,
          });
        });
    },
    updateApp() {
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] +
          "/org/apps/updateApp/" +
          this.appID,
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          appName: this.appName,
          appDescription: this.appDesc,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "App updated! Reloading page...",
            life: 2000,
          });
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while updating the app. \n" +
              err.response.data.errors[0].msg +
              (err.response.data.errors[1]
                ? "\n" + err.response.data.errors[1].msg
                : ""),
            life: 5000,
          });
        });
    },
    addDomain(domain) {
      axios({
        method: "post",
        url: this.$store.getters["getServerURL"] + "/org/apps/addDomain/",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          domain: domain,
          appID: this.appID,
        },
      })
        .then((resp) => {
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Domain added! You can verify the domain ownership now.",
            life: 5000,
          });
          this.domainAdded = true;
        })
        .catch((err) => {
          // console.log(err);
          this.domainAdded = false;
          document
            .getElementById("domainNameInput")
            .classList.add("is-invalid");

          setTimeout(() => {
            document
              .getElementById("domainNameInput")
              .classList.remove("is-valid");
            document
              .getElementById("domainNameInput")
              .classList.remove("is-invalid");
          }, 5000);

          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Error while adding the domain name to the app: " +
              err.response.data.message,
            life: 5000,
          });
        });
    },
    verifyRecords() {
      document.getElementById(
        "verifyDomainOwnershipBtn" + this.appIdentifier
      ).innerHTML = "Verifying...";
      document.getElementById(
        "verifyDomainOwnershipBtn" + this.appIdentifier
      ).disabled = true;
      axios({
        method: "post",
        url:
          this.$store.getters["getServerURL"] + "/org/apps/verifyDomainRecords",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          domain: this.addDomainName,
          appID: this.appID,
        },
      })
        .then((resp) => {
          // console.log(resp.data);
          this.domainRecordVerified = true;
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Domain is now verified!",
            life: 2000,
          });
          document.getElementById(
            "verifyDomainOwnershipBtn" + this.appIdentifier
          ).innerHTML = "Verified";

          localStorage.removeItem(
            "lastDomainVerificationTime" + this.addDomainName
          );

          setInterval(() => {
            //get app
            axios
              .get(
                this.$store.getters["getServerURL"] +
                  "/org/apps/getApp/" +
                  this.appID,
                {
                  headers: { Authorization: localStorage.getItem("jwt") },
                }
              )
              .then((resp) => {
                if (resp.data.domainSSLGenerated) {
                  this.sslDeployed = true;

                  this.$confetti.start();
                  setTimeout(() => {
                    this.$confetti.stop();
                  }, 2000);

                  setTimeout(() => {
                    this.$router.go(this.$router.currentRoute);
                  }, 3000);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
          this.domainRecordVerified = false;
          this.$toast.add({
            severity: "warn",
            summary: "Warning",
            detail: err.response.data.message,
            life: 5000,
          });

          const now = Date.now();

          localStorage.setItem(
            "lastDomainVerificationTime" + this.addDomainName,
            now + 120000
          );

          document.getElementById(
            "verifyDomainOwnershipBtn" + this.appIdentifier
          ).innerHTML = "Verify Ownership";
          document.getElementById(
            "verifyDomainOwnershipBtn" + this.appIdentifier
          ).disabled = false;
        });
    },
    removeDomain() {
      axios({
        method: "delete",
        url: this.$store.getters["getServerURL"] + "/org/apps/removeDomain",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          domain: this.domainName
            ? this.domainName
            : this.domainAdded
            ? this.addDomainName
            : null,
          appID: this.appID,
        },
      })
        .then((resp) => {
          // console.log(resp.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Domain removed! Reloading page...",
            life: 2000,
          });

          localStorage.removeItem(
            "lastDomainVerificationTime" + this.addDomainName
          );
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 2000);
        })
        .catch((err) => {
          // console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: "Unable to remove the domain. Please try again later.",
            life: 5000,
          });
        });
    },
    validateDomain(domain) {
      const domainRegex = /^(?=.{1,253}$)(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z]{2,})+$/;
      return domainRegex.test(domain);
    },

    confirmDisconnect() {
      if (
        !confirm(
          "Are you sure you want to disconnect this domain from " +
            this.title +
            ":\n\n " +
            this.domainName +
            "?"
        )
      ) {
        return;
      } else {
        this.disconnectDomain(this.domainName);
      }
    },
    disconnectDomain(domain) {
      axios({
        method: "delete",
        url: this.$store.getters["getServerURL"] + "/org/apps/disconnectDomain",
        headers: { Authorization: localStorage.getItem("jwt") },
        data: {
          domain: domain,
          appID: this.appID,
        },
      })
        .then((resp) => {
          // console.log(resp.data);
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Domain disconnected from this app! Reloading page...",
            life: 3000,
          });

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 3000);
        })
        .catch((err) => {
          // console.log(err);
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail:
              "Unable to disconnect the domain from this app. Please try again later.",
            life: 5000,
          });
        });
    },

    lastVerificationValid() {
      const now = Date.now();

      if (
        parseFloat(
          localStorage.getItem(
            "lastDomainVerificationTime" + this.addDomainName
          )
        ) > now
      ) {
        return true;
      }

      return false;
    },
    openDrawer() {
      this.drawerVisible = true;
    },
    getSubdomain(url) {
      // Remove protocol if present
      const hostname = url.replace(/^(https?:\/\/)?(www\.)?/, "");

      // Split hostname by "."
      const parts = hostname.split(".");

      // Check if there are enough parts for a subdomain
      if (parts.length > 2) {
        return parts.slice(0, parts.length - 2).join(".");
      }

      return null; // No subdomain found
    },
  },
};
</script>

<style scoped>
.w-30-rem {
  width: 30rem !important;
}

.stepper .line {
  width: 2px;
  background-color: lightgrey !important;
}
.blur-it {
  filter: blur(4px);
}

/* HTML: <div class="ribbon">Your text content</div> */
.ribbon-sm {
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}
.ribbon-sm {
  --f: 0.4em; /* control the folded part*/
  --r: 0.4em; /* control the ribbon shape */

  position: absolute;
  top: 10px;
  right: calc(-1 * var(--f));
  padding-inline: 2em;

  line-height: 1.8;
  /* background: #1f54ff; */
  border-bottom: var(--f) solid #0005;
  border-left: var(--r) solid #0000;
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--f)),
    calc(100% - var(--f)) 100%,
    calc(100% - var(--f)) calc(100% - var(--f)),
    0 calc(100% - var(--f)),
    var(--r) calc(50% - var(--f) / 2)
  );
}
</style>
