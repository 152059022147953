<template>
  <!-- AI -->

  <svg
    fill="none"
    :height="height"
    viewBox="0 0 500 500"
    :width="width"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    id="fi_18525350"
  >
    <clipPath id="clip0_1352_611">
      <path d="m0 0h500v500h-500z"></path>
    </clipPath>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        transform="translate(-2319.000000, -291.000000)"
        :fill="fill"
        fill-rule="nonzero"
      >
        <g transform="translate(1716.000000, 291.000000)">
          <g transform="translate(603.000000, 0.000000)">
            <path
              class="color-background"
              d="m287 36.5c0 20.1584-16.342 36.5-36.5 36.5s-36.5-16.3416-36.5-36.5 16.342-36.5 36.5-36.5 36.5 16.3416 36.5 36.5z"
            ></path>
            <path
              class="color-background"
              clip-rule="evenodd"
              d="m250.5 73h-62.5c-54.858 0-101.3963 35.624-117.7427 85h-2.2573c-37.5554 0-68 30.445-68 68s30.4446 68 68 68h1.6141c15.739 50.411 62.7899 87 118.3859 87h125c55.596 0 102.647-36.589 118.386-87h.614c37.555 0 68-30.445 68-68s-30.445-68-68-68h-1.257c-16.347-49.376-62.885-85-117.743-85zm121.186 145c0 25.637-20.784 46.421-46.422 46.421s-46.421-20.784-46.421-46.421c0-25.638 20.783-46.422 46.421-46.422s46.422 20.784 46.422 46.422zm-197.265 46.421c25.638 0 46.422-20.784 46.422-46.421 0-25.638-20.784-46.422-46.422-46.422-25.637 0-46.421 20.784-46.421 46.422 0 25.637 20.784 46.421 46.421 46.421z"
              fill-rule="evenodd"
            ></path>
            <path
              class="color-background"
              d="m89.9146 500c29.9584-58.197 90.6234-98 160.5854-98s130.627 39.803 160.585 98z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    height: {
      type: String,
      default: "12px",
    },
    width: {
      type: String,
      default: "12px",
    },
    fill: {
      type: String,
      default: "#ffffff",
    },
  },
};
</script>
