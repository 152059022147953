<template>
  <div class="container dashboard-container-fluid">
    <section class="mt-3 pt-2">
      <div class="row mt-3">
        <div class="col-12">
          <h3><i class="fa fa-box"></i> Apps</h3>
          <p class="text-sm">
            This page displays all the apps associated with your account. Each
            card represents an app with its unique ID, identifier, and
            description. You can view the associated storage bucket and compute
            by clicking on "View Bucket" and "View Compute".
          </p>
        </div>
      </div>
      <div class="row mt-4">
        <div
          class="col-12 mt-10 mb-10 d-flex justify-content-center"
          v-if="loading"
        >
          <Spinner />
        </div>
        <template v-if="!loading">
          <div
            class="mb-4 col-lg-4 col-md-4"
            data-aos="fade-up"
            data-aos-once="true"
          >
            <place-holder-card :title="{ text: 'New App', variant: 'h5' }" />
          </div>
          <div
            class="mb-4 col-lg-4 col-md-4"
            v-for="app in apps"
            :key="app._id"
          >
            <app-card
              :active="app.active"
              :appID="app._id"
              :title="app.appName"
              :description="app.appDescription"
              :lastUpdated="app.updatedOn"
              :apiKey="app.apiKey"
              :apiKeyGenTime="app.apiKeyGenTime"
              :appIdentifier="app.appIdentifier"
              :domainName="app.domainName || ''"
              :domainOwnershipVerified="app.domainOwnershipVerified || false"
              :domainOwnershipVerificationTime="
                app.domainOwnershipVerificationTime || 9999999999999
              "
              :domainSSLGenerated="app.domainSSLGenerated || false"
              :domainSSLGenerationTime="app.domainSSLGenerationTime || null"
            />
          </div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import PlaceHolderCard from "./components/PlaceHolderCard.vue";
import AppCard from "./components/AppCard.vue";
import Avatar from "vue-boring-avatars";
import axios from "axios";
import Spinner from "../../components/Spinner.vue";
export default {
  name: "Apps",
  components: {
    AppCard,
    PlaceHolderCard,
    Avatar,
    Spinner,
  },
  data() {
    return {
      showMenu: false,
      apps: [],
      loading: true,
    };
  },

  mounted() {
    // this.$store.state.isAbsolute = false;
    // this.$store.state.isNavFixed = false;
    // setNavPills();

    //fetch all apps

    axios
      .get(this.$store.getters["getServerURL"] + "/org/apps/getAllApps", {
        headers: { Authorization: localStorage.getItem("jwt") },
      })
      .then((response) => {
        // console.log(response);
        this.apps = response.data;
        this.apps.reverse();
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
};
</script>
